import React from 'react'
import { MESSAGES } from './constants'

export interface StaticNotificationProps {
  buttonText?: string,
  buttonUrl?: string,
  class?: string,
  close?: any,
  isMobile: boolean,
  linkText?: string,
  linkUrl?: string,
  message?: string,
  name: string,
  push?: any,
  timeOut?: number,
}
export const StaticNotification = (props: StaticNotificationProps) => {
  if (props.timeOut) {
    setTimeout(() => props.close(props.name), props.timeOut)
  }
  return <div className={`static-notification ${props.class ? props.class : 'standard'}`}>
            <div className="banner-text">
              {props.message || MESSAGES[props.name]}&nbsp;
              {props.linkUrl &&
                <a className="static-notification-link" href={props.linkUrl}>{props.linkText}</a>
              }
            </div>
            {props.buttonUrl &&
              <div className="banner-close clickable-item additional-banner-btn"
                onClick={() => {
                  props.close(props.name)
                  props.push(props.buttonUrl)
                }}>
                {props.buttonText}
              </div>
            }
            {!!props.close &&
            <div className="banner-close clickable-item"
              onClick={() => props.close(props.name)}>
            {props.isMobile ? 'X' : 'Close'}
            </div>
            }
        </div>
}
