import { RasaContext } from 'context'
import { differenceInSeconds, parseISO } from 'date-fns'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import { MESSAGES } from './constants'

interface CountdownProps {
  close: any,
  isMobile: boolean,
  name: string,
  message?: string,
  timeTillDate: string,
}

interface CountdownState {
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
}

export class CountdownComponent extends Component<CountdownProps, CountdownState> {
  public static contextType = RasaContext
  private _interval: any
  constructor(props: CountdownProps) {
    super(props)
    this.state = {
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
    }
  }

  public componentDidMount() {
    this._interval = setInterval(() => {
      const { timeTillDate } = this.props
      const then = parseISO(timeTillDate)
      const now = new Date()
      const diffInSec = differenceInSeconds(then, now)
      const hours = Math.floor(diffInSec / 3600)
      const minutes = Math.floor((diffInSec % 3600) / 60)
      const seconds = diffInSec - (hours * 3600 + minutes * 60)
      if (hours === 0 && minutes === 0 && seconds === 0) {
        setTimeout(() => this.props.close(this.props.name), 1000)
        this.componentWillUnmount()
      } else {
        this.setState({ hours, minutes, seconds })
      }
    }, 1000)
  }

  public componentWillUnmount() {
    if (this._interval) {
      clearInterval(this._interval)
    }
  }

  public render() {
    const { hours, minutes, seconds } = this.state
    const hourString = hours > 0 ? pluralize('hours', hours, true) : ''
    const minuteString = minutes > 0 ? pluralize('minutes', minutes, true) : ''
    const secondsString = seconds > 0 ? pluralize('seconds', seconds, true) : ''
    const time = [hourString, minuteString, secondsString].join(' ')
    return (
      <div className="countdown-banner">
        <div className="banner-text">
          {(this.props.message || MESSAGES[this.props.name]).replace('{time}', time)}
        </div>
        <div className="banner-close clickable-item"
          onClick={() => this.props.close(this.props.name)}>
          {this.props.isMobile ? 'X' : 'Close'}
        </div>
      </div>
    )
  }
}
