import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DropdownComponent, OnChangeEvent } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { format } from 'date-fns'
import { DateRangesAsDropdownOptions, esToNormalDateRange } from 'elasticsearch/constants'
import { AggregationType, DateRanges, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import { Dataset } from 'generic/dataset'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import { uniqWith } from 'lodash'
import * as React from 'react'
import { Button } from 'reactstrap'
import { DEFAULT_COMMUNITY_PARTNER_CODES } from 'shared_server_client/constants'
import { dateToString, formatDateForES } from 'shared_server_client/dates'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import {ComponentType} from "react";
import {Fields} from "../../shared/modals";

const initialState = {
  communityId: null,
  issues: [],
  issuesLoading: true,
  selectedDateRange: DateRangesAsDropdownOptions[2],
  selectedSegmentCode: DEFAULT_COMMUNITY_PARTNER_CODES.ALL,
}

export class SendSummariesComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props, initialState)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
    this.context.user.init().then(({person, activeCommunity}) => {
      return this.setState({
        communityId: activeCommunity.communityId,
      }, () => this.loadIssues())
    })
  }

  public render() {
    return(
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Send Summaries'}
        />
        <div className="dropdown-header">
          <div className="dropdown-inline">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                                selected={this.state.selectedDateRange.key}
                                onChange={this.dateChanged}/>
          </div>
        </div>
          <div className="date-range-picker">
          {this.state.selectedDateRange.key === '7' ?
          <DateRangePicker min={this.state.minCustomDateRange} max ={new Date()}
          onChange={this.createCustomDate} /> : null}
          </div>
          <div className="dropdown-segment-code">
        <LoadSegmentCodes segmentCode={this.state.selectedSegmentCode} setSegmentCode={this.segmentCodeChanged}
          hideLabel={true}></LoadSegmentCodes>
        </div>
        {this.state.issuesLoading &&
          <AnalyticsSendSummariesComponent dateRange={this.state.selectedDateRange.value}
                                         selectedSegmentCode={this.state.selectedSegmentCode}
                                         issues={this.state.issues} />}
      </div>
    )
  }

  public dateChanged = (e: OnChangeEvent) => {
    this.setState({
      selectedDateRange: e.selected,
    }, () => {
      if (e.selected.key !== '7') {
        this.loadIssues()
      }
    })
  }

  public createCustomDate = (e) => {
    if (e.value.start === null || e.value.end === null) {
      return
    } else {
       const customRange = `${formatDateForES(e.value.start)}|${formatDateForES(e.value.end)}`
       this.setState({
        selectedDateRange: {
          ...this.state.selectedDateRange,
          value: customRange,
         },
      }, () => this.loadIssues())
    }
  }

  private loadIssues = () => {
    const dateRanges = esToNormalDateRange(this.state.selectedDateRange.value).split('|')
    const params = [
      {param: 'minimumSent', value: '0'}, // number 0 is getting dropped and not passing to endpoint - fix later
      {param: 'startDate', value: dateRanges[0]},
      {param: 'endDate', value: dateRanges[1]},
      {param: 'pageSize', value: 100},
    ]
    return new Dataset().loadCommunityDataset('communityIssues', this.state.communityId, params)
      .then((issues) => {
        let issuesData = issues[0].map((i) => {
          const sendDate: Date = new Date(i.send_at_in_timezone)
          return {
            send_date: sendDate,
            expected: i.expected - (i.skipped || 0),
            id: i.id,
          }
        })
        issuesData = uniqWith(issuesData, (d: any, i: any) => d.id === i.id)
        this.setState({
          issueLoading: false,
          issues: issuesData,
        })
      })
  }
}

interface Issue {
  timestamp: string,
  expected: number,
  others: number,
  totalDelivered: number,
  uniqueSoftBounces: number,
  uniqueSpams: number,
  uniqueBounces: number,
  uniqueDropped: number,
}

type Issues = Issue[]

interface SendSummariesProps extends ElasticsearchProps<Issues> {
  issues: any[],
  dateRange: string,
  selectedSegmentCode: string,
}

interface SendSummariesState {
  loaded: boolean,
  displayLabel: boolean,
}

const EVENT_AGGREGATION: string = 'event'
const DATE_AGGREGATION: string = 'date'

class SendSummariesClass extends ElasticsearchComponent<Issues, SendSummariesProps, SendSummariesState> {
  private xlsxExport: any = null
  constructor(p: SendSummariesProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      displayLabel: true,
    }
    this.reportName = Constants.REPORT_NAMES.SEND_SUMMARIES
  }

  public parseResponse(payload: ResponsePayload): Promise<Issues> {
    this.setState({
      loaded: true,
    })
    const dateAggregationsBuckets = payload.aggregations[DATE_AGGREGATION].buckets
    .filter((aggregation: ResponseAggregate) => {
      return this.getAggregation(aggregation.child, 'delivered')
    })
    return Promise.resolve(dateAggregationsBuckets.map((aggregation: ResponseAggregate) => {
      const delivered = this.getAggregation(aggregation.child, 'delivered')
      const softBounce = this.getAggregation(aggregation.child, 'soft_bounce')
      const bounces = this.getAggregation(aggregation.child, 'hard_bounce')
      const dropped = this.getAggregation(aggregation.child, 'dropped')
      const spamReport = this.getAggregation(aggregation.child, 'spamreport')
      const d = new Date(new Date(aggregation.key))
      const issues = this.props.issues
      let expected = 0
      expected = issues.filter((x) => dateToString(new Date(x.send_date)) === dateToString(d))
      .reduce((issueCount, x) => {
        return ((expected + x.expected))
      }, 0)

      const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
      const totalDelivered = delivered ? delivered.doc_count : 0
      const uniqueSoftBounces = softBounce && softBounce.unique ? softBounce.unique.value : 0
      const uniqueSpams = spamReport && spamReport.unique ? spamReport.unique.value : 0
      const uniqueBounces = bounces && bounces.unique ? bounces.unique.value : 0
      const uniqueDropped = dropped && dropped.unique ? dropped.unique.value : 0
      const others = expected - (totalDelivered + uniqueSoftBounces + uniqueBounces + uniqueSpams + uniqueDropped )

      const partial: any = {
        unixTimestamp: aggregation.key,
        timestamp: format(new Date(dateWithoutOffset), 'iii, MMM do'),
        expected,
        others,
        totalDelivered,
        uniqueSoftBounces,
        uniqueSpams,
        uniqueBounces,
        uniqueDropped,
      }
      return partial
    }))
  }

  public componentDidUpdate(oldProps: SendSummariesProps) {
    if ( this.props.dateRange !== oldProps.dateRange
      || this.props.issues !== oldProps.issues
      || this.props.selectedSegmentCode !== oldProps.selectedSegmentCode
    ) {
      this.search()
      this.tooMuchData()
    }
  }

  public searchPayload(): any {
    const search = bodybuilder().size(0).filter(
      FilterType.range, 'message_send_date', toFilter(this.props.dateRange || DateRanges.PastMonth))
      .filter(FilterType.terms, 'issue_id', this.props.issues.map((x) => x.id))

    if (this.props.selectedSegmentCode === DEFAULT_COMMUNITY_PARTNER_CODES.NO_CODE) {
      search.notFilter(FilterType.exists, 'community_partner_code.keyword')
    } else if (this.props.selectedSegmentCode !== DEFAULT_COMMUNITY_PARTNER_CODES.ALL) {
      search.filter(FilterType.term, 'community_partner_code.keyword', this.props.selectedSegmentCode)
    }

    return this.addAggregation(search, {
      type: AggregationType.date_histogram,
      field: 'message_send_date',
      extra: { interval: 'day' },
      name: DATE_AGGREGATION,
      child: {
        type: AggregationType.terms,
        field: 'event_name.keyword',
        name: EVENT_AGGREGATION,
        unique_on: 'community_person_id',
      },
    }).build()
  }

  public tooMuchData = () => {
    const display = (this.props.results && this.props.results.length < Constants.INTERVAL_THRESHOLD)
    this.setState({displayLabel: !display})
  }

  public navigateToDailyStats = (props) => {
    return (
      <td colSpan={1}>
        <button type="button" className="popoutLink"
           onClick={(e) => {
             e.preventDefault()
             this.props.push(`/analytics/dailystats?date_filter=${props.dataItem.unixTimestamp}`)
           }}
        >
        {props.dataItem.timestamp + ' '}
        </button>
      </td>
    );
  }

  public render = () => <div className="analytics">
    <div>
      {this.props.results && this.props.results.length > 0 ?
        <div>
          <div className="send-summaries">
            <Button
              disabled={this.props.results.length < 1}
              onClick={() => this.xlsxExport.save()}>
              Export xlsx
            </Button>
          <ExcelExport data={this.props.results}
            fileName="RasaAdminReports.xlsx"
            ref={(exporter) => {this.xlsxExport = exporter}}>
            <Grid data={this.props.results} className="analytics-counts-grid" scrollable="none">
              <GridColumn field="timestamp" title="Date" cell={this.navigateToDailyStats}/>
              <GridColumn field="expected" title="Sent"/>
              <GridColumn field="totalDelivered" title="Delivered"/>
              <GridColumn field="uniqueSoftBounces" title="Soft Bounces"/>
              <GridColumn field="uniqueBounces" title="Hard Bounces"/>
              <GridColumn field="uniqueSpams" title="Spams"/>
              <GridColumn field="uniqueDropped" title="Dropped"/>
            </Grid>
          </ExcelExport>
          </div>
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
      </div>}
      </div>
  </div>
}

export const AnalyticsSendSummariesComponent: ConnectedComponentClass<ComponentType<SendSummariesClass>, Fields> = GenericRedux.registerNewComponent(
  SendSummariesClass, 'analytics_sendsummaries', {})
