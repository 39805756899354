import * as KendoChart from '@progress/kendo-react-charts'
import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridCell, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DropdownComponent } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { format } from 'date-fns'
import { AggregateIntervalOptions, DateRangesAsDropdownOptions, SuspectFilterDropdownOptions
} from 'elasticsearch/constants'
import { AggregationType, DateRanges, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button } from 'reactstrap'
import { DEFAULT_COMMUNITY_PARTNER_CODES } from 'shared_server_client/constants'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from "react-redux"
import {Fields} from "../../shared/modals";
import {ComponentType} from "react";
import * as Utils from './utils'

const initialState = {
  selectedDateRange: DateRangesAsDropdownOptions[2],
  selectedInterval: AggregateIntervalOptions[0],
  selectedSegmentCode: DEFAULT_COMMUNITY_PARTNER_CODES.ALL,
}
export class OpensClicksComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props, initialState)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return(
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Opens & Clicks'}
          description={[
            'Opens - The number of times your newsletter has been opened by your subscribers.',
            'Clicks - The number of times any of your subscribers have clicked on a link in your newsletter.',
            'Click to Open - the number of clicks out of the number of opens',
          ]}
        />
        <div className="dropdown-header">
          <div className="dropdown-inline">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                                selected={this.state.selectedDateRange.key}
                                onChange={this.dateChanged}/>
          </div>
          <div className="dropdown-inline">
            <DropdownComponent data={AggregateIntervalOptions}
                                selected={this.state.selectedInterval.key}
                                onChange={this.intervalChanged}/>
          </div>
          <div className="dropdown-inline">
            <DropdownComponent data={SuspectFilterDropdownOptions}
                               selected={this.state.selectedSuspectClick.key}
                               onChange={this.suspectedClickChanged}/>
          </div>
        </div>
        <div className="dropdown-segment-code">
        <LoadSegmentCodes segmentCode={this.state.selectedSegmentCode} setSegmentCode={this.segmentCodeChanged}
          hideLabel={true}></LoadSegmentCodes>
        </div>
        <div className="date-range-picker">
        {this.state.selectedDateRange.key === '7' ?
        <DateRangePicker min={this.state.minCustomDateRange}
                          max ={new Date()}
                          onChange={this.createCustomDate} /> : null}
        </div>
        <AnalyticsOpensClicksComponent dateRange={this.state.selectedDateRange.value}
                                      selectedSegmentCode={this.state.selectedSegmentCode}
                                      selectedInterval={this.state.selectedInterval.value}
                                      suspectClick={this.state.selectedSuspectClick.value}/>
      </div>
    )
  }
}

// const totalOpenColor = '#7fae1b'
const uniqueOpenColor = '#004f6d'
// const totalClickColor = '#8ae0e3'
const uniqueClickColor = '#4ecdc4'
const uniqueClickToOpenColor = '#ff6b6b'

interface Point {
  timestamp: string,
  totalClick: number,
  totalClickRate: number,
  totalDelivered: number,
  totalOpen: number,
  totalOpenRate: number,
  uniqueClick: number,
  uniqueClickRate: number,
  uniqueDelivered: number,
  uniqueOpen: number,
  uniqueOpenRate: number,
  uniqueClickToOpenRate?: number,
  suspectClick?: number,
}

type Points = Point[]

interface EngagementProps extends ElasticsearchProps<Points> {
  dateRange: string,
  selectedInterval: string,
  hideGrid: boolean,
  selectedSegmentCode: string,
  suspectClick?: any
}

interface EngagementState {
  loaded: boolean,
  displayLabel: boolean,
}

const EVENT_AGGREGATION: string = 'event'
const DATE_AGGREGATION: string = 'date'

class OpensClicksClass extends ElasticsearchComponent<Points, EngagementProps, EngagementState> {
  private xlsxExport: any = null
  constructor(p: EngagementProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      displayLabel: true,
    }
    this.reportName = Constants.REPORT_NAMES.OPEN_CLICKS
  }

  public parseResponse(payload: ResponsePayload): Promise<Points> {
    this.setState({
      loaded: true,
    })
    const dateAggregationsBuckets = payload.aggregations[DATE_AGGREGATION].buckets
    .filter((aggregation: ResponseAggregate) => {
      return this.getAggregation(aggregation.child, 'delivered')
    })
    return Promise.resolve(dateAggregationsBuckets.map((aggregation: ResponseAggregate) => {
      const clicks = this.getAggregation(aggregation.child, 'click')
      const opens = this.getAggregation(aggregation.child, 'open')
      const delivered = this.getAggregation(aggregation.child, 'delivered')
      const bounces = this.getAggregation(aggregation.child, 'hard_bounce')
      const unique_soft_bounces = this.getAggregation(aggregation.child, 'soft_bounce')
      const dropped = this.getAggregation(aggregation.child, 'dropped')
      const d = new Date(new Date(aggregation.key))
      const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
      const partial: any = {
        timestamp: format(new Date(dateWithoutOffset), 'iii, MMM do'),
        totalClick: clicks ? clicks.doc_count : 0,
        totalDelivered: delivered ? delivered.doc_count : 0,
        totalOpen: opens ? opens.doc_count : 0,
        uniqueClick: clicks && clicks.unique ? clicks.unique.value : 0,
        uniqueDelivered: delivered && delivered.unique ? delivered.unique.value : 0,
        uniqueOpen: opens && opens.unique ? opens.unique.value : 0,
        uniqueBounces: (bounces && bounces.unique ? bounces.unique.value : 0 ) +
                       (dropped && dropped.unique ? dropped.unique.value : 0),
        uniqueSoftBounces: unique_soft_bounces && unique_soft_bounces.unique ? unique_soft_bounces.unique.value : 0,
      }
      return {
        ...partial,
        totalClickRate: (
          (partial.totalDelivered > 0 ?
            partial.totalClick / partial.totalDelivered : 0) * 100.0).toFixed(2),
        totalOpenRate: (
          (partial.totalDelivered > 0 ?
            partial.totalOpen / partial.totalDelivered : 0) * 100.0).toFixed(2),
        uniqueClickRate: (
          (partial.uniqueDelivered > 0 ?
            partial.uniqueClick / partial.uniqueDelivered : 0) * 100.0).toFixed(2),
        uniqueOpenRate: (
          (partial.uniqueDelivered > 0 ?
            partial.uniqueOpen / partial.uniqueDelivered : 0) * 100.0).toFixed(2),
        uniqueClickToOpenRate: (
          (partial.uniqueClick > 0 && partial.uniqueOpen > 0 ?
            partial.uniqueClick / partial.uniqueOpen : 0) * 100.0).toFixed(2),
      }
    }))
  }

  public componentDidUpdate(oldProps: EngagementProps) {
    if ( this.props.dateRange !== oldProps.dateRange  || this.props.suspectClick !== oldProps.suspectClick) {
      this.search()
      this.tooMuchData()
    }
    if ( this.props.selectedInterval !== oldProps.selectedInterval ||
      this.props.selectedSegmentCode !== oldProps.selectedSegmentCode ) {
      this.search()
      this.tooMuchData()
    }
  }

  public searchPayload(): any {
    const search = bodybuilder().size(0)
       .filter(FilterType.range, 'message_send_date', toFilter(this.props.dateRange || DateRanges.PastMonth))

    if (this.props.selectedSegmentCode === DEFAULT_COMMUNITY_PARTNER_CODES.NO_CODE) {
      search.notFilter(FilterType.exists, 'community_partner_code.keyword')
    } else if (this.props.selectedSegmentCode !== DEFAULT_COMMUNITY_PARTNER_CODES.ALL) {
      search.filter(FilterType.term, 'community_partner_code.keyword', this.props.selectedSegmentCode)
    }
    if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
      search.addFilter(FilterType.term, 'suspect_click', 0)
    }

    return this.addAggregation(search, {
      type: AggregationType.date_histogram,
      field: 'message_send_date',
      extra: { interval: this.props.selectedInterval || 'day' },
      name: DATE_AGGREGATION,
      child: {
        type: AggregationType.terms,
        field: 'event_name.keyword',
        name: EVENT_AGGREGATION,
        unique_on: 'community_person_id',
      },
    }).build()
  }

  public tooMuchData = () => {
    const display = (this.props.results && this.props.results.length < Constants.INTERVAL_THRESHOLD)
    this.setState({displayLabel: !display})
  }

  public render = () => <div className="analytics">
    <div className="engagement-chart">
      {this.props.results && this.props.results.length > 0 ?
        <div>
          <KendoChart.Chart transitions={false} pannable={false} zoomable={false}>
          <KendoChart.ChartLegend position="bottom" orientation="horizontal"/>
          <KendoChart.ChartTooltip
            render={({points}: KendoChart.SharedTooltipContext) => <div className="engagement-tooltip">
              <div>
                {points[0].dataItem.timestamp || ''}
              </div>
            {points.map((point) =>
              <div key={point.series.name + point.value} style={{color: point.series.color}}>
                {point.series.name}: {point.value}%
              </div>)}
            </div>}
            shared={true} />
            <KendoChart.ChartCategoryAxis>
            {this.props.results.length < Constants.INTERVAL_THRESHOLD &&
              <KendoChart.ChartCategoryAxisItem
                categories={this.props.results.map((item) => item.timestamp)}/>}
            </KendoChart.ChartCategoryAxis>
            <KendoChart.ChartSeries>
              <KendoChart.ChartSeriesItem
                type="line"
                color={uniqueClickColor}
                field="uniqueClickRate"
                name="Unique Click"
                data={this.props.results}>
                  {this.props.results && this.props.results.length < Constants.INTERVAL_THRESHOLD &&
                  <KendoChart.ChartSeriesLabels format="{0:N2}%" />}
              </KendoChart.ChartSeriesItem>
              <KendoChart.ChartSeriesItem
                type="line"
                color={uniqueClickToOpenColor}
                field="uniqueClickToOpenRate"
                name="Click To Open"
                data={this.props.results}>
                  {this.props.results && this.props.results.length < Constants.INTERVAL_THRESHOLD &&
                  <KendoChart.ChartSeriesLabels format="{0:N2}%" />}
              </KendoChart.ChartSeriesItem>
              <KendoChart.ChartSeriesItem
                type="line"
                color={uniqueOpenColor}
                field="uniqueOpenRate"
                name="Unique Open"
                data={this.props.results}>
                  {this.props.results && this.props.results.length < Constants.INTERVAL_THRESHOLD &&
                  <KendoChart.ChartSeriesLabels format="{0:N2}%" />}
              </KendoChart.ChartSeriesItem>
            </KendoChart.ChartSeries>
          </KendoChart.Chart>
          {!this.props.hideGrid &&
          <div className="opens-clicks">
            <Button
              disabled={this.props.results.length < 1}
              onClick={() => this.xlsxExport.save()}>
              Export xlsx
            </Button>
          <ExcelExport data={this.props.results}
            fileName="RasaAdminReports.xlsx"
            ref={(exporter) => {this.xlsxExport = exporter}}>
            <Grid data={this.props.results} className="analytics-counts-grid" scrollable="none">
              <GridColumn field="timestamp" title="Date"/>
              {/* <GridColumn field="totalOpenRate" title="Total Open Rate" format="{0:N2}%"/>
              <GridColumn field="totalClickRate" title="Total Click Rate" format="{0:N2}%"/>
              <GridColumn field="uniqueOpenRate" title="Unique Open Rate" format="{0:N2}%"/>
              <GridColumn field="uniqueClickRate" title="Unique Click Rate" format="{0:N2}%" /> */}
              <GridColumn field="totalOpen" title="Total Opens"/>
              <GridColumn field="totalClick" title="Total Clicks"/>
              <GridColumn field="uniqueOpen" title="Unique Opens"/>
              <GridColumn field="uniqueClick" title="Unique Clicks"/>
              <GridColumn field="uniqueClickToOpenRate" title="Clicks To Open" cell={ClickToOpenCell}/>
              <GridColumn field="totalDelivered" title="Delivered"/>
              <GridColumn field="uniqueBounces" title="Bounces"/>
              <GridColumn field="uniqueSoftBounces" title="Soft Bounces"/>
            </Grid>
          </ExcelExport>
          </div>}
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
      </div>}
      </div>
  </div>

}

class ClickToOpenCell extends GridCell {
  public render() {
    const d = this.props.dataItem.uniqueClickToOpenRate
    return <td>
      <span>{d}%</span>
    </td>
  }
}

export const AnalyticsOpensClicksComponent: ConnectedComponentClass<ComponentType<OpensClicksClass>, Fields> = GenericRedux.registerNewComponent(
  OpensClicksClass, 'analytics_opensclicks', {})
