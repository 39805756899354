import * as GlobalConstants from '../../constants'
import * as Constants from './constants'
import * as Types from './types'

const appCuesJSON = {
  source_added: {
    max_times_show: 3,
    data: [{
      title: 'Source Added!',
      content: '<p><b>Consider adding Filters to bring in the most relevant articles</b><br /> \
                You probably don\'t need to filter your own blog, Facebook or Twitter Sources.</p>',
      image: {
        url: 'https://res.cloudinary.com/dnjrorsut/image/upload/v1583342918/56565/usj0u9vnybmzod4bfckr.png',
      },
      next: {
        text: 'Go to Filters',
        url: '/content/filters',
      },
      close: {
        text: 'No, Thanks',
      },
    },
  ]},
  filter_guide: {
    max_times_show: 1,
    data: [{
      title: 'What are Filters?',
      content: '<p><b>Include</b> and <b>Exclude</b> will keep your newsletter on topic.</p> \
                <p>The AI will only pull in articles that fully match the filters you set.</p>',
      next: {
        text: 'Next',
      },
    },
    {
      title: 'Filters should be a topic, keyword or phrase.',
      content: '<p>Example: The AnimalsThatRead Newsletter might use these filters for \
                sources that publish lots of articles, like ZNN or The Zoo York Times.</p> \
                <p style="color: #8cd4cd"><b>Include:</b> Animal Kingdom, Mammals, Reptiles, Ambhibians</p> \
                <p style="color: red"><b>Exclude:</b> Extinction, Poachers</p>',
      next: {
        text: 'Next',
      },
      close: {
        text: 'Back',
      },
    },
    {
      title: 'Apply your filters in different ways.',
      content: '<p>Filters can be added in groups (separated by commmas) or individually.</p> \
                <p>Once added, just select the sources you want to apply them to.</p>',
      next: {
        text: 'Next',
      },
      close: {
        text: 'Back',
      },
    },
    {
      title: 'What happens next?',
      content: '<p>If the article matches one or your include filters, you\'ll see it in Upcoming.</p> \
                <p>Alternatively, if it matches one of your Exclude filters, we will keep it out.</p>',
      next: {
        text: 'Done',
      },
      close: {
        text: 'Back',
      },
    },
  ]},
  articles_100: {
    max_times_show: 1,
    data: [{
      title: 'That\'s a lot of articles!',
      content: '<p>Consider adding some filters.</p>',
      next: {
        text: 'Go to Filters',
          url: '/content/filters',
      },
    }],
  },
  company_info_validation: {
    max_times_show: -1,
    data: [{
      title: 'Company info missing!',
      content: `<p>In order to start sending your newsletter, you will need to enter your Company name and Company address on the \
                <a href="/settings/company">newsletters settings page</a> to comply with \
                <a target="_blank" href="${GlobalConstants.RASA_HELP_ANTI_SPAM_EMAIL_DELIVERABILITY}"> \
                CAN-SPAM requirements.</a></p>`,
      next: {
        text: 'Go to Settings',
          url: '/settings/company',
      },
    }],
  },
}

export const getAppCues = (): Promise<any> => {
  return Promise.resolve(appCuesJSON)
}
export const isShowAppCue = (key: Constants.APP_CUE_TYPE, appCues: any) => {
  const numTimesShowed = Number(window.localStorage.getItem(Constants.APP_CUE_FREQUENCY + key))
  const maxTimesShow = appCues && appCues[key] ? Number(appCues[key].max_times_show) : -1
  return maxTimesShow === -1 || numTimesShowed < maxTimesShow
}
export const getAppCueByKey = (
  appCues: any,
  key: Constants.APP_CUE_TYPE): Types.AppCue[] => {
  return appCues && appCues[key] ? appCues[key].data : Constants.DEFAULT_APP_CUE
}
