const GenerationWindowMessage =
  'Today’s newsletter has already generated. Any articles entered now will be in your next newsletter.'
const OverContactLimitMessage =
  'You currently have more subscribers than your plan allows.  Contact us to Upgrade your account.'
const ExpiredAccountMessage =
  'Your free trial has expired.  Please contact us to extend it, or visit the Account page to ugprade your plan.'
const AccountCancelledMessage =
  'Your account has been cancelled.  You will continue to have access to the data until {endDate}'
const TrialExpiringMessage =
  'Your free trial will expire on {endDate}.'
const TrialExpiringSoonMessage =
  'Your free trial will expire in {time}'
const NewsletterWillSendMessage =
  'Your next newsletter will generate in {time}'
const CommunitySystemDisconnected =
  'One or more of your integrations are disconnected.'
const FailedCommunityIntegration =
    'Your integration is failing. Please contact.'
const InvalidDomainMessage =
  'Your email domain is not valid anymore.'
const ManualScheduleIsEnabled =
  'Your Manual Schedule is enabled.'
const AccountDunningMessage = 'Your account is past due. Please update your payment information to ensure continued newsletter delivery.'

export const CAPPED = 'AccountLimitFailure'
export const CLOSE_BANNER = 'CLOSE_BANNER'
export const OPEN_BANNER = 'OPEN_BANNER'
export const CLOSED = 'closed'
export const OPEN = 'open'
export const DATE_FORMAT = 'ccc, MMM dd, yyyy'
export const DATE_FORMAT_MOBILE = 'MMM dd'
export const WEEKLY = 'weekly'
export const BIWEEKLY = 'biweekly'
export const DAILY = 'daily'
export const COMMUNITY_SYSTEM_LINK_TEXT = 'Click here to reconnect.'
export const UPDATE_LINK_TEXT = 'Click here to Update.'

export enum BannerType {
  ACCOUNT_CANCELLED = 'AccountCancelled',
  ACCOUNT_DUNNING = 'AccountDunning',
  ACCOUNT_SUSPENDED = 'AccountSuspended',
  COUNTDOWN = 'countdown',
  EXPIRED_ACCOUNT = 'ExpiredAccount',
  IS_APPROACHING_LIMIT = 'IsApproachingLimit',
  IN_GENERATION = 'inGeneration',
  INVALID_DOMAIN = 'InvalidDomain',
  MANUAL_SCHEDULE_ENABLED = 'manualScheduleEnabled',
  OVER_CONTACT_LIMIT = 'overContactLimit',
  SYSTEM_STATUS = 'SystemStatus',
  WONT_SEND = 'WontSend',
  TRIAL_EXPIRING = 'TrialExpiring',
  TRIAL_EXPIRING_SOON = 'TrialExpiringSoon',
  COMMUNITY_SYSTEM_DISCONNECTED = 'ÇommunitySystemDisconnected',
  FAILED_COMMUNITY_INTEGRATION = 'FailedCommunityIntegration'
}

export const MESSAGES = {
  [BannerType.ACCOUNT_CANCELLED]: AccountCancelledMessage,
  [BannerType.ACCOUNT_DUNNING]: AccountDunningMessage,
  [BannerType.COUNTDOWN]: NewsletterWillSendMessage,
  [BannerType.EXPIRED_ACCOUNT]: ExpiredAccountMessage,
  [BannerType.IN_GENERATION]: GenerationWindowMessage,
  [BannerType.OVER_CONTACT_LIMIT]: OverContactLimitMessage,
  [BannerType.TRIAL_EXPIRING]: TrialExpiringMessage,
  [BannerType.TRIAL_EXPIRING_SOON]: TrialExpiringSoonMessage,
  [BannerType.COMMUNITY_SYSTEM_DISCONNECTED]: CommunitySystemDisconnected,
  [BannerType.FAILED_COMMUNITY_INTEGRATION]: FailedCommunityIntegration,
  [BannerType.INVALID_DOMAIN]: InvalidDomainMessage,
  [BannerType.MANUAL_SCHEDULE_ENABLED]: ManualScheduleIsEnabled,
}
