import * as Constants from './constants'

export const loadAppCues = (appCues) => ({
  type: Constants.APP_CUES_LOADED,
  appCues,
})

export const showAppCue = (cueType: Constants.APP_CUE_TYPE) => ({
  type: Constants.SHOW_APP_CUE,
  cueType,
})

export const movePage = (pageNumber: number) => ({
  type: Constants.MOVE_PAGE,
  pageNumber,
})

export const closeAppCue = () => ({
  type: Constants.CLOSE_APP_CUE,
})
