import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DropdownComponent} from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { AggregationType, DateRangeFilter, FilterType, IndexName, SuspectFilterDropdownOptions, toFilter } from 'elasticsearch/constants'
import { DateRangesAsDropdownOptions } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button } from 'reactstrap'
import { DEFAULT_COMMUNITY_PARTNER_CODES } from 'shared_server_client/constants'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import * as Utils from './utils'
import { ConnectedComponentClass } from 'react-redux'
import {ComponentType} from "react";
import {Fields} from "../../shared/modals";

export class NewClicksComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      selectedDateRange: DateRangesAsDropdownOptions[2],
      selectedSuspectClick: SuspectFilterDropdownOptions[0],
      selectedSegmentCode: DEFAULT_COMMUNITY_PARTNER_CODES.ALL,
    }
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Other Clicks'}
        />
        <div className="dropdown">
          <div className="grid-container">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                              selected={this.state.selectedDateRange.key}
                              onChange={this.dateChanged}/>
            <DropdownComponent data={SuspectFilterDropdownOptions}
                              selected={this.state.selectedSuspectClick.key}
                              onChange={this.suspectedClickChanged}/>
          </div>
        </div>
        <div className="dropdown-segment-code">
        <LoadSegmentCodes segmentCode={this.state.selectedSegmentCode} setSegmentCode={this.segmentCodeChanged}
          hideLabel={true}></LoadSegmentCodes>
        </div>
        <div className="date-range-picker">
          {this.state.selectedDateRange.key === '7' ?
          <DateRangePicker min={this.state.minCustomDateRange}
                           max ={new Date()}
                           onChange={this.createCustomDate} /> : null}
        </div>
      {this.state.isFilterLoaded &&
        <AnalyticsUrlClickTable dateRange={this.state.selectedDateRange.value}
                selectedSegmentCode={this.state.selectedSegmentCode}
                suspectClick={this.state.selectedSuspectClick.value}/>}
      </div>
    )
  }

}

interface UrlStats {
  url: string,
  title?: string,
  totalClicks: number,
  uniqueClicks: number,
}

interface UrlInfo {
  url: string,
}

type Url = UrlStats & UrlInfo

type Urls = Url[]

interface ArticlesState {
  loaded: boolean,
}

interface UrlProps extends ElasticsearchProps<Urls> {
  dateRange: any,
  suspectClick?: any,
  selectedSegmentCode?: any,
}

const PRIMARY_AGG = 'clicked_url'
const SECONDARY_AGG = 'title'

class UrlClicksTableComponent extends ElasticsearchComponent<Urls, UrlProps, ArticlesState> {
  private xlsxExport: any = null
  constructor(p: UrlProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
    }
    this.reportName = Constants.REPORT_NAMES.OTHER_CLICKS
  }

  public parseResponse(payload: ResponsePayload): Promise<any> {
    this.setState({
      loaded: true,
    })
    const clickedUrlAggregations = payload.aggregations[PRIMARY_AGG]
    const statsResponse = []
    clickedUrlAggregations.buckets.map((aggregation: ResponseAggregate) =>  {
      if (!aggregation.child || !aggregation.child.buckets || !aggregation.child.buckets.length) {
        statsResponse.push({
          url: aggregation.key,
          title: '',
          totalClicks: aggregation.doc_count,
          uniqueClicks: aggregation.unique.value,
          realClicks: aggregation.doc_count,
        })
      } else {
        const titleResponse = aggregation.child.buckets.map((titleAggregation: ResponseAggregate) => {
          return ({
            url: aggregation.key,
            title: titleAggregation.key,
            totalClicks: titleAggregation.doc_count,
            uniqueClicks: titleAggregation.unique.value,
            realClicks: titleAggregation.doc_count,
          })
        })
        const titleTotalClicks = titleResponse.reduce((accumulator, agg) => {
          return accumulator + agg.totalClicks
        }, 0);
        const titleUniqueClicks = titleResponse.reduce((accumulator, agg) => {
          return accumulator + agg.uniqueClicks
        }, 0);
        if (aggregation.doc_count !== titleTotalClicks) {
          statsResponse.push({
            url: aggregation.key,
            title: '',
            totalClicks: aggregation.doc_count - titleTotalClicks,
            uniqueClicks: aggregation.unique.value - titleUniqueClicks,
            realClicks: aggregation.doc_count - titleTotalClicks,
          })
        }
        statsResponse.push(...titleResponse)
      }
    })

    return Promise.resolve(statsResponse);
   }

  public componentDidUpdate(oldProps: UrlProps) {
    if ( this.props.dateRange !== oldProps.dateRange || this.props.suspectClick !== oldProps.suspectClick
      || this.props.selectedSegmentCode !== oldProps.selectedSegmentCode ) {
      this.search()
    }
  }

  public searchPayload(): any {
    const dateRangeFilter: DateRangeFilter = toFilter(this.props.dateRange)
    if (dateRangeFilter.gte && dateRangeFilter.lt) {
      const search = bodybuilder().size(0)
        .filter(FilterType.range, 'message_send_date', dateRangeFilter)
        .filter(FilterType.term, 'event_name.keyword', 'click')
        .notFilter(FilterType.exists, 'post_subscription_id')
        .notFilter(FilterType.exists, 'community_promotion_id')
        .notFilter(FilterType.exists, 'static_image_id')
      if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
        search.addFilter(FilterType.term, 'suspect_click', 0)
      }

      if (this.props.selectedSegmentCode === DEFAULT_COMMUNITY_PARTNER_CODES.NO_CODE) {
        search.notFilter(FilterType.exists, 'community_partner_code.keyword')
      } else if (this.props.selectedSegmentCode !== DEFAULT_COMMUNITY_PARTNER_CODES.ALL) {
        search.filter(FilterType.term, 'community_partner_code.keyword', this.props.selectedSegmentCode)
      }
      return this.addAggregation(search, {
        type: AggregationType.terms,
        field: 'normalized_click_url.keyword',
        name: PRIMARY_AGG,
        extra: { size: '100' },
        unique_on: 'community_person_id',
        child: {
          field: 'title.keyword',
          name: SECONDARY_AGG,
          type: AggregationType.terms,
          unique_on: 'community_person_id',
          extra: { size: '100' },
        },
      }).build()
    }
  }

  public render = () => {
    const displayClickType: any = Utils.getDisplayedClickType(this.props.suspectClick)
    return <div>
      <div className="articles-chart">
        {this.props.results && this.props.results.length > 0 ?
        <div className="other-clicks">
          <Button
            disabled={this.props.results.length < 1}
            onClick={() => this.xlsxExport.save()}>
            Export xlsx
          </Button>
          <ExcelExport data={this.props.results}
            fileName="RasaAdminReports.xlsx"
            ref={(exporter) => {this.xlsxExport = exporter}}>
            <Grid data={this.props.results} className="analytics-counts-grid">
              <GridColumn field="title" title="Title" width={200}/>
              <GridColumn field="url" title="Url" width={500}/>
              <GridColumn field={displayClickType.field} title={displayClickType.title} width={150}/>
              <GridColumn field="uniqueClicks" title="Unique Clicks" width={150}/>
            </Grid>
          </ExcelExport>
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
        </div>}
      </div>
    </div>
  }
}

export const AnalyticsUrlClickTable: ConnectedComponentClass<ComponentType<UrlClicksTableComponent>, Fields> = GenericRedux.registerNewComponent(
  UrlClicksTableComponent,
  'home_urls',
  {},
)
