import * as ReduxObservable from 'redux-observable'
import * as Router from 'router'
import { map, switchMap } from 'rxjs/operators'
import * as Constants from './constants'
import * as Utils from './utils'

export const onEnter: ReduxObservable.Epic = (action$) =>
  action$.ofType(Router.ON_ENTER)
    .pipe(
      switchMap(() =>
        Utils.getAppCues(),
      ),
      map((appCues) => ({type: Constants.APP_CUES_LOADED, appCues})),
    )

export const onShow: ReduxObservable.Epic = (action$, state$) =>
  action$.ofType(Constants.SHOW_APP_CUE)
    .pipe(
      map((action, state) => {
        const isShow = Utils.isShowAppCue(action.cueType, state$.value.appCues.app_cues)
        if (isShow) {
          const numTimesShowed = Number(sessionStorage.getItem(Constants.APP_CUE_FREQUENCY + action.cueType))
          window.localStorage.setItem(Constants.APP_CUE_FREQUENCY + action.cueType, String(numTimesShowed + 1))
        }
        return {action, isShow}
      }),
      map(({action, isShow}) => ({type: Constants.SHOW_APP_CUE_CONFIRM, cueType: action.cueType, isShow})),
    )

export const epic = ReduxObservable.combineEpics(onEnter, onShow)
