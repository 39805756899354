import * as Constants from './constants'
import * as Types from './types'

export const reducer = (state: Types.Notification = {}, action: Types.SetClosedNotificationAction) => {
  switch (action.type) {
    case Constants.CLOSE_BANNER:
      return {
        ...state,
        [action.notificationName]: Constants.CLOSED,
      }
    case Constants.OPEN_BANNER:
      return {
        ...state,
        [action.notificationName]: Constants.OPEN,
      }
    default:
      return state
  }
}
