import React from 'react'
import * as Constants from './constants'
import * as Utils from './utils'

declare const RASA_IMAGE_API_ENDPOINT: string

interface LinkShortenProps {
  linkShortenUrl: any,
  title: string,
  user: any,
  url: string
}
interface LinkShortenState {
  error: boolean,
}

export class LinkShorten extends React.Component<LinkShortenProps, LinkShortenState> {
  constructor(props: LinkShortenProps) {
    super(props)
    this.state = {
      error: false,
    }
  }

  public onClick(share: Constants.SocialMediaShare) {
    event.preventDefault();
    const urlPromise = Utils.urlShorten(this.props.linkShortenUrl, share)
    return urlPromise
  }

  public render() {
    return <td className="social-media">
      {Utils.ALL_SOCIAL_MEDIAS
        .map((m) => m.generator(this.props.url, this.props.title, this.props.user)).map((s, i) =>
          <span key={s.type} className="share">
            <a href={s.url}
                target="_blank"
                title={s.title}
                rel="noopener noreferrer"
                onClick={() => this.onClick(s)}>
              <img id="img-logo" src={`${RASA_IMAGE_API_ENDPOINT}/hosted-images-newsbrief/path/social-media/${s.type}_64_color_circle.png?fit=crop&w=48&h=48`}/>
            </a>
          </span>)}
        </td>
  }
}
