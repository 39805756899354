import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { DropdownComponent, DropdownOption, OnChangeEvent } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { RasaContext } from 'context'
import { AggregationType, FilterType, IndexName} from 'elasticsearch/constants'
import { Dataset } from 'generic/dataset'
import { ElasticsearchComponent, ElasticsearchProps } from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import React, { ComponentType } from 'react'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from "react-redux"
import {Fields} from "../../shared/modals";

interface AbTestOption {
  id: number,
  name: string,
  share: number
}

interface AbTest {
  id: number,
  name: string,
  options: AbTestOption[],
  startDate: any,
  endDate: any,
}

interface AbTestState {
  isLoading: boolean,
  selectedTest?: AbTest,
  tests: AbTest[],
}

const initialState: AbTestState = {
  isLoading: false,
  tests: [],
}

export class ABTestsComponent extends RasaAnalyticsComponent<any, AbTestState> {
  constructor(props) {
    super(props, initialState)
  }

  public render() {
    return(
      <div className="ab-tests">
        <HeaderComponent
          title={'AB Tests'}
          subTitle={'A/B Test Results'}
          description={['Show the outcomes of any of your recent A/B Tests']}
        />
        {this.state.tests.length > 0 &&
        <div className="ab-tests-body">
          <DropdownComponent data={this.testsAsOptions()}
                             selected={(this.state.selectedTest || {}).id}
                             onChange={this.testChanged}/>
          {this.state.selectedTest &&
          <div className="ab-tests-detail">
            <strong>Launched: {this.state.selectedTest.startDate}</strong><br/>
            <strong>Finished: {this.state.selectedTest.endDate}</strong><br/>
            <strong>Options:</strong>
            <ul>
              {this.state.selectedTest.options.map((o: AbTestOption) => (
                <li key={o.id}>{o.name} with {o.share} percent</li>
              ))}
            </ul>
            <AbTestStatsTable testId={this.state.selectedTest.id}/>
          </div>
          }
        </div>
        }
      </div>
    )
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      return this.loadAbTests(activeCommunity.communityId)
    })
  }

  private loadAbTests(communityId) {
    return new Dataset().loadCommunityDataset('communityAbTests', communityId)
      .then((results) => {
        const abTestOptions = results[1]
        const abTests = results[0].map((r) => {
          return {
            id: r.id,
            name: r.name,
            startDate: r.start_date,
            endDate: r.end_date,
            options: abTestOptions.filter((o) => o.ab_test_id === r.id),
          }
        })
        this.setState({
          isLoading: false,
          tests: abTests,
        })
        if (abTests.length > 0) {
          this.setState({selectedTest: abTests[0]})
        }
      })
  }

  private testChanged = (e: OnChangeEvent) => {
    this.setState({
      selectedTest: this.state.tests.find((t) => t.id === e.selected.key),
    })
  }

  private testsAsOptions(): DropdownOption[] {
    return this.state.tests.map((t: AbTest) => this.testAsOption(t))
  }

  private testAsOption(t: AbTest): DropdownOption {
    return {
      key: t.id,
      description: t.name,
    }
  }
}

const PRIMARY_AGG = 'by_option'

interface AbTestStat {
  opens: number,
  openRate: number,
  uniqueOpens: number,
  clicks: number,
  clickRate: number,
  uniqueClicks: number,
  deliveries: number,
  name: string
}

type AbTestStats = AbTestStat[]

interface AbTestStatsProps extends ElasticsearchProps<AbTestStats>  {
  testId: any
}

interface AbTestStatsState {
  loaded: boolean,
}

class AbTestStatsComponent extends ElasticsearchComponent<AbTestStats, AbTestStatsProps, AbTestStatsState> {
  public static contextType = RasaContext
  constructor(p: AbTestStatsProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
    }

  }

  public componentDidUpdate(oldProps: AbTestStatsProps) {
    if ( this.props.testId !== oldProps.testId ) {
      this.search()
    }
  }

  public parseResponse(payload: any): Promise<AbTestStats> {
    this.setState({
      loaded: true,
    })
    const optionResults = payload.aggregations[PRIMARY_AGG]
    if ( optionResults && optionResults.buckets ) {
      return optionResults.buckets.map((agg) => {
        const clickAgg: any = this.getAggregation(agg.child, 'click') || {}
        const openAgg: any = this.getAggregation(agg.child, 'open') || {}
        const deliverAgg: any = this.getAggregation(agg.child, 'delivered') || {}
        return {
          opens: openAgg.doc_count,
          clicks: clickAgg.doc_count,
          deliveries: deliverAgg.doc_count,
          name: agg.key,
          uniqueClicks: (clickAgg.unique || {}).value,
          uniqueOpens: (openAgg.unique || {}).value,
          clickRate: (clickAgg.doc_count / deliverAgg.doc_count) * 100,
          openRate: (openAgg.doc_count / deliverAgg.doc_count) * 100,
        }
      })
    } else {
      return Promise.resolve([])
    }
  }

  public searchPayload(): any {
    const search = bodybuilder().size(5)
      .filter(FilterType.term, 'ab_test', this.props.testId)

    const payload: any = this.addAggregation(search, {
        type: AggregationType.terms,
        field: 'ab_test_option_name.keyword',
        name: PRIMARY_AGG,
        extra: { size: '25' },
        child: {
          field: 'event_name.keyword',
          type: AggregationType.terms,
          name: 'by_event',
          extra: {
            size: '10',
          },
          unique_on: 'community_person_id',
        },
      }).build()
    return payload
  }

  public render = () => {
    return <div>
      <div className="articles-chart">
        {this.props.results && this.props.results.length > 0 ?
        <div className="articles">
          <Grid data={this.props.results} className="analytics-counts-grid">
            <GridColumn field="name" title="Test Option Name"/>
            <GridColumn field="clicks" title="Clicks"/>
            <GridColumn field="clickRate" title="Click Rate" format="{0:N2}%"/>
            <GridColumn field="opens" title="Opens"/>
            <GridColumn field="openRate" title="Open Rate" format="{0:N2}%"/>
          </Grid>
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
        </div>}
      </div>
    </div>
  }
}

const AbTestStatsTable: ConnectedComponentClass<ComponentType<AbTestStatsComponent>, Fields> = GenericRedux.registerNewComponent(
  AbTestStatsComponent, 'ab_test_stats', {})
