import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { DropdownComponent} from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { format } from 'date-fns'
import { AggregationType, FilterType, IndexName } from 'elasticsearch/constants'
import { Dataset } from 'generic/dataset'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import * as React from 'react'
import { Button } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import {ComponentType} from "react";
import {Fields} from "../../shared/modals";

export class SourcesReportComponent extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props, dailyStatsInitialState)
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
    this.setState({
      issuesLoading: false,
      communityId: activeCommunity.communityId,
      issues: this.loadIssues(activeCommunity.communityId),
      selectedIssue: {key: 'Select a Newsletter', value: -1},
      })
    })
  }

  public render() {
    return(
      <div className="analytics-component">
        <HeaderComponent
          title={'REPORTS'}
          subTitle={'Newsletter Source Report'}
          description={['This report shows how many active articles from a past send were from each source.']}
        />
        {this.state.issuesLoading ?
          <Loading size="64" /> :
          (this.state.issues.length && !this.props.hideHeader) ?
          <div>
            <div className="dropdown articles">
              <div className="grid-container">
                <DropdownComponent
                  data={this.state.issues}
                  selected={this.state.selectedIssue.key}
                  onChange={this.issueChanged}
                />
              </div>
            </div>
            {this.state.selectedIssue.value > 0 &&
              <AnalyticsSourcesReportTable issue_id={this.state.selectedIssue.value}
                                           sourceName={this.state.selectedSourceName.value}/>
            }
          </div>
          :
          <div>
            <p className="no-data-tag">{Constants.NO_DATA_COPY}</p>
          </div>
        }
    </div>
    )
  }
 private loadIssues = (communityId) => {
    return this.getIssueDataForFilter(communityId)
      .then((issueDataForFilter) => {
        if (issueDataForFilter.length > 1) {
          this.setState({selectedIssue: issueDataForFilter[1]})
        }
        this.setState({
          issuesLoading: false,
          issues: issueDataForFilter,
          })
        })
  }
  private getIssueDataForFilter = (communityId) => {
    return new Dataset().loadCommunityDataset('communityIssues', communityId, null, 10)
      .then((issues) => {
        const allIssuesData = issues[0]
          .filter((i) => i.content_pool_size > 0)
          .map((i) => {
            const d: Date = new Date(i.send_at_in_timezone)
            const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
            const key = format(new Date(dateWithoutOffset), 'iiii, MMM d H:mm')
            return {
              description: key,
              key,
              value: i.id,
            }
          })
        const issueDataForFilter = allIssuesData.length ? [
          {
            key: 'Select a Newsletter',
            divider: true,
            value: '-1',
          },
          ...allIssuesData,
        ] : []

        return issueDataForFilter
    })
  }
  private issueChanged = (e: any) => {
    this.setState({selectedIssue: e.selected})
  }
}

const dailyStatsInitialState = {
  issuesLoading: true,
  issues: [],
  selectedIssue: {key: 'No Issue', value: -1},
  deliveryCount: null,
  communityId: null,
}

interface SourceReportStats {
  sourceName?: string,
  totalClicks?: number
}

type SourceReport = SourceReportStats

type SourceReports = SourceReport[]

interface SourceReportsState {
  loaded: boolean,
  user: any,
  sources: any,
}

const SOURCE_NAME_AGG: string = 'source_name'

interface SourcesReportsProps extends ElasticsearchProps<SourceReports> {
  sourceName?: any,
  issue_id: string,
}

class SourceReportTableComponent extends ElasticsearchComponent<SourceReports,
  SourcesReportsProps, SourceReportsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private xlsxExport: any = null
  constructor(p: SourcesReportsProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      user: {},
      sources: {},
    }
    this.reportName = Constants.REPORT_NAMES.REPORTS
  }
  public parseResponse(payload: any): Promise<SourceReports> {
    this.setState({
      loaded: true,
    })
    const aggregations = payload.aggregations[SOURCE_NAME_AGG]
    return Promise.resolve(this.state.sources.map((source) => {
      const sourceAggregation = aggregations.buckets.find((aggregation) => aggregation.key === source.name)
      if (sourceAggregation) {
        return {
          ...source,
          totalClicks: sourceAggregation.doc_count,
        }
      } else {
        return {
          ...source,
          totalClicks: 0,
        }
      }
    }))
  }
  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
      this.sharedStore.getValue(SharedKeys.config),
    ]).then(([activeCommunity, person, config]) => {
      this.communityId = activeCommunity.communityId
      this.setState({
        user: {
          email: person.email,
          firstName: person.firstName,
          lastName: person.lastName,
        },
      })
      this.getSourceReportData().then((dsResults) => {
        this.search()
      })
    })
  }
  public componentDidUpdate(oldProps: SourcesReportsProps) {
    if ( this.props.issue_id !== oldProps.issue_id ) {
      this.setState({loaded: false})
      this.search()
    }
  }

  public searchPayload(): any {
      const search = bodybuilder().size(0)
      .filter(FilterType.term, 'issue_id', this.props.issue_id)
      .filter(FilterType.term, 'event_name.keyword', 'click')
      return this.addAggregation(search, {
        type: AggregationType.terms,
        field: 'source_name.keyword',
        name: SOURCE_NAME_AGG,
        extra: { size: '25' },
      }).build()
  }

  public render = () => {
   return <div>
    <div className="articles-chart">
      {this.props.results && this.props.results.length > 0 ?
      <div className="articles">
        <Button
          disabled={this.props.results.length < 1}
          onClick={() => this.xlsxExport.save()}>
          Export xlsx
        </Button>
        <ExcelExport data={this.props.results}
          fileName="RasaSourceReports.xlsx"
          ref={(exporter) => {this.xlsxExport = exporter}}>
        <Grid data={this.props.results.map((a: any) => ({
            ...a,
            user: this.state.user,
          }))} className="analytics-counts-grid">
            <GridColumn field="name" title="Source"/>
            <GridColumn field="articles_count" title="Articles#"/>
            <GridColumn field="totalClicks" title="Total Clicks"/>
        </Grid>
        </ExcelExport>
      </div> :
      <div>
        <p className="no-data-tag">
          {Constants.NO_DATA_COPY}
        </p>
      </div>}
    </div>
  </div>
  }

  private getSourceReportData = () => {
    return new Dataset().loadCommunityDataset('contentPoolReportForSources', this.communityId,
      [{param: 'issue_id', value: this.props.issue_id}]).then((sources) => {
          if (sources[0].length > 0) {
            this.setState({
              sources: sources[0],
        })
      }
   })
  }
}
export const AnalyticsSourcesReportTable: ConnectedComponentClass<ComponentType<SourceReportTableComponent>, Fields> = GenericRedux.registerNewComponent(
  SourceReportTableComponent, 'source_report', {})
