import * as Constants from './constants'
import * as Types from './types'

const emptyState: Types.AppCuesState = {
  cue_type: null,
  current_page: 0,
  is_open: false,
}
export const reducer = (state: Types.AppCuesState = emptyState, action: Types.AppCuesAction) => {
  switch (action.type) {
    case Constants.APP_CUES_LOADED: {
      return {
        ...state,
        app_cues: action.appCues,
      }
    }
    case Constants.SHOW_APP_CUE_CONFIRM:
      return {
        ...state,
        cue_type: action.cueType,
        is_open: action.isShow,
      }
    case Constants.MOVE_PAGE:
      return {
        ...state,
        current_page: action.pageNumber,
      }
    case Constants.CLOSE_APP_CUE:
      return {
        ...state,
        ...emptyState,
      }
    default:
      return state
  }
}
