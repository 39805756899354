import classnames from 'classnames'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as Router from 'router'
import * as Actions from './actions'
import * as Constants from './constants'
import './styles.css'
import * as Utils from './utils'

interface AppCuesComponentState {
  appCues: any,
  cueType: Constants.APP_CUE_TYPE,
  currentPage: number,
  isOpen: boolean,
  isSaving?: boolean,
}
export class AppCuesComponent extends React.Component<any, AppCuesComponentState> {
  public static contextType = RasaContext
  constructor(props: any) {
    super(props)
    this.state = {
      appCues: null,
      cueType: null,
      currentPage: 0,
      isSaving: false,
      isOpen: false,
    }
  }
  public componentWillReceiveProps(nextProps) {
    if (nextProps.appCues) {
      this.setState({
        appCues: nextProps.appCues.app_cues,
        cueType: nextProps.appCues.cue_type,
        currentPage: nextProps.appCues.current_page,
        isOpen: nextProps.appCues.is_open,
      })
    }
  }
  public render() {
    const selectedCues = Utils.getAppCueByKey(this.state.appCues, this.state.cueType)
    const selectedCue = selectedCues[this.state.currentPage]
    return selectedCue ? <Modal
              className={'app-cues-modal rasa-modal modal-lg'}
              isOpen={this.state.isOpen}>
              <ModalHeader toggle={() => this.close()}>
              </ModalHeader>
              <ModalBody className={selectedCues.length > 1 ? 'fixed' : ''}>
                {this.renderChildren(selectedCue)}
              </ModalBody>
              <ModalFooter>
                  <div className="footer-buttons">
                    { selectedCue.close ?
                    <Button className={classnames('primary',
                          !this.props.isMobile ? {'with-margin': true} : {},
                          this.props.isMobile ? 'mobile' : '')}
                            onClick={() => this.onCloseClick(selectedCue)}>
                      {selectedCue.close.text}
                    </Button> : null }
                    { selectedCue.next ? <Button
                      className={`primary btn-next ${this.props.isMobile ? 'mobile' : ''}`}
                      onClick={() => this.onNextClick(selectedCues)}>
                      {selectedCue.next.text}
                    </Button> : null }
                  </div>
              </ModalFooter>
            </Modal> : null
  }
  protected renderChildren(selectedCue) {
    return (
      <div className="add-newsletter-modal-body">
        {this.state.isSaving
        ? <Loading size="64"/>
        : <div>
            {selectedCue.image &&
              <img width={selectedCue.image.width ? selectedCue.image.width : 'auto'}
                height={selectedCue.image.height ? selectedCue.image.height : 'auto'}
                src={selectedCue.image.url} />}
            <div className="title">{selectedCue.title}</div>
            {selectedCue.content &&
              <div className="content" dangerouslySetInnerHTML={{__html: selectedCue.content}}></div>}
          </div>
        }
      </div>
    )
  }

  protected onNextClick = (selectedCues) => {
    if (selectedCues[this.state.currentPage].next && selectedCues[this.state.currentPage].next.url) {
      window.location.replace(selectedCues[this.state.currentPage].next.url)
    } else if (selectedCues.length > this.state.currentPage + 1) {
      this.props.movePage(this.state.currentPage + 1)
    } else {
      this.close()
    }
  }

  protected onCloseClick = (selectedCue) => {
    if (selectedCue.close && selectedCue.close.text.toLowerCase().indexOf('back') > -1) {
      this.props.movePage(this.state.currentPage - 1)
    } else {
      this.close()
    }
  }

  protected close = () => {
    return this.props.closeModal()
  }
}

export const AppCuesModal = connect(
  ({appCues}: any) => ({appCues}),
  {
    push: Router.push,
    closeModal: Actions.closeAppCue,
    movePage: Actions.movePage,
  },
)(AppCuesComponent)
