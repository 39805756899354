import * as Constants from 'components/banner/constants'
import { UpgradeAnchorLink } from 'components/link'
import { format } from 'date-fns'
import * as GA from 'google-analytics'
import React from 'react'
import { DATE_FORMAT, DATE_FORMAT_MOBILE } from 'shared_server_client/constants'
import { UsageStats } from 'shared_server_client/types/usage_stats'

export type UsageAction = (name: string) => void
export interface UsageLimitProps {
  class: string,
  close: UsageAction,
  isMobile: boolean,
  name: string,
  nextBillingDate: any,
  sendReminder?: UsageAction,
  usageStats: UsageStats,
}

export const UsageLimit = (props: UsageLimitProps) => {
  const dateFormat = props.isMobile ? DATE_FORMAT_MOBILE : DATE_FORMAT
  const messageDate = format(new Date(props.nextBillingDate), dateFormat)
  const message1 = props.name === Constants.BannerType.IS_APPROACHING_LIMIT ?
    `You will hit your limit of ${props.usageStats.currentPlanIncludedMessages.toLocaleString()} messages before ${messageDate}.` :
    `Your ${props.usageStats.currentPlanIncludedMessages.toLocaleString()} messages will renew on ${messageDate}. No more messages will be sent until then.`

  if (!props.isMobile) {
    const message2 = props.name === Constants.BannerType.IS_APPROACHING_LIMIT ?
      'now to avoid an interruption in sends.' :
      'to Send More.'

    return <div className={`static-notification ${props.class}`}>
              <div className="banner-text">
                <div>
                  {message1} &nbsp;
                </div>
                <div>
                  <UpgradeAnchorLink source={GA.UpgradeSource.UsageLimit}
                    callback={() => {
                      props.close(props.name)
                    }}>
                    Upgrade
                  </UpgradeAnchorLink>
                {' ' + message2}
                </div>
              </div>
              <div className="banner-close clickable-item"
                onClick={() => props.close(props.name)}>
              {'Close'}
              </div>
          </div>
  } else {
      const message2 = props.name === Constants.BannerType.IS_APPROACHING_LIMIT ?
        'Login via desktop and upgrade to avoid an interruption in sends.' :
        'Login via desktop and upgrade to Send More.'
      return <div className={`static-notification-${props.class} mobile`}>
        <div>
                <div className="banner-text-mobile">
                  <div className="notification-line-1">
                    {message1} {' '} {message2}
                  </div>
                </div>
                <div>
                <UpgradeAnchorLink
                    source={GA.UpgradeSource.UsageLimit}
                    callback={() => {
                      props.close(props.name)
                    }}>
                    Upgrade
                  </UpgradeAnchorLink>
              </div>
            </div>
            <div className="banner-close clickable-item"
              onClick={() => props.close(props.name)}>
              {'X'}
            </div>
          </div>
  }
}
