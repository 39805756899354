import { GridCell } from '@progress/kendo-react-grid'
import React from 'react'
import { LinkShorten } from './share-cell-generator'

export class ShareCell extends GridCell {
  public render() {
    return <LinkShorten url={this.props.dataItem.shareUrl || this.props.dataItem.url}
                        linkShortenUrl={this.props.dataItem.linkShortenUrl}
                        title={this.props.dataItem.title}
                        user={this.props.dataItem.user}/>
  }
}
