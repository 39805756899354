import bodybuilder from 'bodybuilder'
import { HeaderComponent } from 'components/header/component'
import {
  AggregationType,
  DateRangesAsDropdownOptions,
  FilterType,
  IndexName,
  toFilter
} from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import { ElasticsearchComponent, ElasticsearchProps } from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import { startCase } from 'lodash'
import React, { ComponentType } from 'react'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import { Fields } from '../../shared/modals'
import * as Modals from '../../shared/modals'
import {
  KNOWLEDGE_TIER_RANGES_BY_LABEL
} from './constants'
import * as Utils from './utils'
import { Loading } from 'components/loading'

const AI_KNOWLEDGE_TOPIC_BUCKET_SIZE = '10'
const COMMUNITY_PERSON_SEARCH_LIMIT = 1000

interface KnowledgeTierTopicStats {
  engagementRate: string,
  subscribers: number,
  totalOpenRate: string,
  uniqueClickRate: string,
  uniqueClicks: number,
}
interface AiKnowledgeTierTopic {
  name: string,
  topic: KnowledgeTierTopicStats,
  knowledgeTier: KnowledgeTierTopicStats,
  communityPersonIds: ResponseAggregate[]
}

type AiKnowledgeTierTopics = AiKnowledgeTierTopic[]

interface AiKnowledgeTierTopicsProps extends ElasticsearchProps<AiKnowledgeTierTopics> {
  dateRange: any,
  source: any,
  openModal: any,
}

interface AiKnowledgeTierTopicsState {
  isLoading: boolean,
  loaded: boolean,
  knowledgeTierData: ResponseAggregate[],
}

const TAG_NAME_AGG: string = 'tag'
const InitialKnowledgeTierTopicStats = {
  engagementRate: '0',
  subscribers: 0,
  totalOpenRate: '0',
  uniqueClickRate: '0',
  uniqueClicks: 0,

}

export class AiKnowledgeTopicComponentClass extends RasaAnalyticsComponent<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      selectedDateRange: DateRangesAsDropdownOptions.filter((x) => x.value ===
        this.getQuery(Constants.AnalyticsFilterKeys.SELECTED_DATE_RANGE, DateRangesAsDropdownOptions[2].value))[0],
      selectedSource: Constants.SourceFilters.filter((x) => x.value ===
        this.getQuery(Constants.AnalyticsFilterKeys.SELECTED_SOURCE, Constants.SourceFilters[0].value))[0],
    }
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public render() {
    const description = '<a href="https://help.rasa.io/ai-knowledge-tier-analytics">Learn more about AI Knowledge Tiers here.</a>'

    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'REPORTS'}
          subTitle={'AI Knowledge Tier Topics'}
          description={[description]}
        />
        <AnalyticsAiKnowledgeTierTopicsTable
          openModal={() => this.props.openModal(ExportAiKnowledgeTopicReportStartedModal.key, {})}
          dateRange={this.state.selectedDateRange.value}
          source={this.state.selectedSource.value} />
        <div className="modal-wrapper">
          <ExportAiKnowledgeTopicReportStartedModal data={this.props.modals} closeModal={this.props.closeModal} title="Export Started" />
        </div>
      </div>
    )
  }
}

class AiKnowledgeTierTopicsTableComponent extends ElasticsearchComponent<AiKnowledgeTierTopics, AiKnowledgeTierTopicsProps, AiKnowledgeTierTopicsState> {
  constructor(p: AiKnowledgeTierTopicsProps) {
    super(p, IndexName.TAGS)
    this.state = {
      loaded: false,
      isLoading: false,
      knowledgeTierData: [],
    }
    this.reportName = Constants.REPORT_NAMES.TOPICS
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      this.communityId = activeCommunity.communityId
      this.search()
      this.getAIKnowledgeTierReport()
    })
  }

  public componentDidUpdate(prevProps: Readonly<AiKnowledgeTierTopicsProps>, prevState: Readonly<AiKnowledgeTierTopicsState>) {
    if( this.state.knowledgeTierData.length > 0 &&
        this.props.results &&
        this.props.results.length > 0 &&
        (
          !prevProps.results ||
          prevProps.results.length !== this.props.results.length ||
          prevState.knowledgeTierData.length !== this.state.knowledgeTierData.length
        )
    ){
      this.getEngagementData()
    }
  }

  protected searchError() {
    this.setState({
      isLoading: false,
      loaded: false,
    })
  }

  public parseResponse(payload: ResponsePayload): Promise<AiKnowledgeTierTopics> {
    const aggregations = payload.aggregations[TAG_NAME_AGG]
    return Promise.resolve(aggregations.buckets.map((aggregation: ResponseAggregate) => {
      this.getOpenClicksForCommunityPersons(aggregation.top_community_person_ids.buckets, aggregation.key, 'topic')
      this.setState({
        isLoading: false,
        loaded: true,
      })
      return {
        name: startCase(aggregation.key.toLowerCase()),
        topic: {
          ...InitialKnowledgeTierTopicStats,
          subscribers: aggregation.top_community_person_ids.buckets.length,
          uniqueClicks: aggregation.doc_count,
        },
        knowledgeTier: InitialKnowledgeTierTopicStats,
        communityPersonIds: aggregation.top_community_person_ids.buckets
      }
    }))
  }

  public searchPayload(): any {
    this.setState({
      isLoading: true,
    })
    return this.generateSearchQuery()
      .aggregation(
        AggregationType.terms,
        'text.keyword',
        {
          field: 'text.keyword',
          size: AI_KNOWLEDGE_TOPIC_BUCKET_SIZE,
        },
        TAG_NAME_AGG,
        aggs =>
          aggs.aggregation(AggregationType.cardinality, 'community_person_id', 'unique')
          .aggregation(
            AggregationType.terms,
            'community_person_id',
            {
              field: 'community_person_id',
              size: COMMUNITY_PERSON_SEARCH_LIMIT,
            },
            'top_community_person_ids'
          )
      ).build()
  }

  private knowledgeTierSearchPayload = () => {
    return bodybuilder()
      .size(0)
      .aggregation(
        AggregationType.range,
        'unique_tags',
        {
          ranges: {
            from: KNOWLEDGE_TIER_RANGES_BY_LABEL.TIER_F_FROM
          },
        },
        'unique_tags',
        aggs => aggs
          .aggregation(AggregationType.cardinality, 'community_person_id', 'unique_count')
          .aggregation(
            AggregationType.terms,
            'community_person_id',
            {
              field: 'community_person_id',
              size: COMMUNITY_PERSON_SEARCH_LIMIT,
            },
            'top_community_person_ids'
          )
      )
      .build()
  }

  private getAIKnowledgeTierReport = () => {
    const searchPayload = this.knowledgeTierSearchPayload()
    this.queryIndex(searchPayload, IndexName.COMMUNITY_PERSON_ACTION_ROLLUP)
      .then((aiKnowledgeTierResult) => {
        const result = aiKnowledgeTierResult.aggregations.unique_tags.buckets
        if(result.length > 0){
          this.setState({
            knowledgeTierData: result[0].top_community_person_ids.buckets
          })
        }
      })
  }

   private getOpenClicksForCommunityPersons =(communityPersonBucket: ResponseAggregate[], topic: string, property: string) => {
    const communityPersonIds = communityPersonBucket.map((x) => Number(x.key))
     const search = this.generateSearchQuery()
       .query(FilterType.terms, 'community_person_id', communityPersonIds)
     const query = this.addAggregation(search, {
       type: AggregationType.terms,
       field: 'event_name.keyword',
       name: 'events',
       unique_on: 'community_person_id',
     }).build()
     this.queryIndex(query, IndexName.EVENTS).then((events) => {
       const result = events.aggregations.events.buckets
       const clickCount= this.findEventCountByKey(result, 'click')
       const openCount = this.findEventCountByKey(result, 'open')
       const deliveredCount = this.findEventCountByKey(result, 'delivered')

       const totalOpenRate = Utils.asPercentage((openCount / deliveredCount) || 0);
       const uniqueClickRate = Utils.asPercentage((clickCount / deliveredCount) || 0);
       const engagementRate = (Number(totalOpenRate) + Number(uniqueClickRate)).toFixed(2);
       const topicIndex = this.findTopicIndex(topic)
       const newResults = [
         ...this.props.results
       ]
       newResults[topicIndex][property] = {
         ...this.props.results[topicIndex][property],
         totalOpenRate,
         uniqueClicks: clickCount,
         uniqueClickRate,
         engagementRate,
       }

       this.props.propertyChanged('results', newResults)
     }).catch((e) => {
       throw new Error(e)
     })
  }

  private findEventCountByKey = (result: ResponseAggregate[], eventKey: string) => {
    const findEvent = result.find((x: ResponseAggregate) => x.key === eventKey)
    return findEvent ? findEvent.doc_count : 0
  }

  private findTopicIndex = (topicName: string) => {
    topicName = startCase(topicName.toLowerCase());
    const topic = this.props.results.find((x) => x.name === topicName)
    if(topic)
      return this.props.results.indexOf(topic)
  }

  private getEngagementData =() =>{
    this.props.results.forEach((topic) => {
      const matchedPersonIds = topic.communityPersonIds.filter((cp) => this.state.knowledgeTierData.find((cp2) => cp2.key === cp.key))
      const topicIndex = this.findTopicIndex(topic.name)
      this.props.results[topicIndex].knowledgeTier = {
        ...this.props.results[topicIndex].knowledgeTier,
        subscribers: matchedPersonIds.length,
      }
      this.getOpenClicksForCommunityPersons(matchedPersonIds, topic.name, 'knowledgeTier')
    })
  }

  public render = () => <div>
    <div className="topics-chart">
      {this.props.results && this.props.results.length > 0 ?
        <div>
          <div className="t-rows-wrapper t-row--dark">
            <h2 className="t-main-heading">Highest Engagement Rate</h2>
            <div>
              {this.card()}
            </div>
          </div>
        </div> :
        <div>
          { this.state.isLoading ?
          <Loading size="64"/> :
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>}
        </div>
      }
    </div>
  </div>

  private card = () => {
    return this.props.results.map((result, index) => {
      return <div className="t-row">
        <strong className="t-count-title">#{index + 1}</strong>
        <div className="t-titles-wrapper">
          <div className="t-align-wrapper">
            <h3 className="t-heading">
              <span className="t-subheading"></span>
              <span>{result.name}</span>
            </h3>
          </div>
        </div>
        <div className="t-data-wrapper">
          <div className="t-align-wrapper">
            <div className="t-table-wrapper">
              <table className="t-table no-clickable">
                <thead>
                <tr>
                  <th></th>
                  <th>Subscribers</th>
                  <th>Open Rate</th>
                  <th>CTR</th>
                  <th>Engagement rate</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <span className="t-data-subheading">Everyone</span>
                    <strong className="t-data-heading">Most Engaged</strong>
                  </td>
                  <td>
                    <span className="t-data-subheading">{this.cappedCount(result.topic.subscribers)}</span>
                    <strong className="t-data-heading">{this.cappedCount(result.knowledgeTier.subscribers)}</strong>
                  </td>
                  <td>
                    <span className="t-data-subheading">{result.topic.totalOpenRate}</span>
                    <strong className="t-data-heading">{result.knowledgeTier.totalOpenRate}%</strong>
                  </td>
                  <td>
                    <span className="t-data-subheading">{result.topic.uniqueClickRate}</span>
                    <strong className="t-data-heading">{result.knowledgeTier.uniqueClickRate}%</strong>
                  </td>
                  <td>
                    <span className="t-data-subheading">{result.topic.engagementRate}</span>
                    <strong className="t-data-heading">{result.knowledgeTier.engagementRate}%</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    })
  }

  private generateSearchQuery = (): bodybuilder.Bodybuilder => {
    return bodybuilder()
      .size(0)
      .filter(
        FilterType.range, 'message_send_date', toFilter('now-1M/d|now/d')
      )
  }

  private cappedCount = (count: number): string => {
    if ( count === COMMUNITY_PERSON_SEARCH_LIMIT )
      return `${count}+`
    else
      return count.toString()
  }
}

class ExportAiKnowledgeTopicReportStartedModal extends Modals.ModalComponent {
  public static key: string = 'aiKnowledgeTierTopicReportExportStart'

  constructor(props: Modals.ModalComponentProps) {
    super(props, ExportAiKnowledgeTopicReportStartedModal.key)
  }

  protected renderChildren(data: any) {
    return 'We are exporting your ai knowledge tier topic report now. Check your inbox for a link to download the list.'
  }
}

export const AnalyticsAiKnowledgeTierTopicsTable: ConnectedComponentClass<ComponentType<AiKnowledgeTierTopicsTableComponent>, Fields> = GenericRedux.registerNewComponent(
  AiKnowledgeTierTopicsTableComponent, 'ai_knowledge_tier_topics', {})

export const AiKnowledgeTierTopicComponent = GenericRedux.registerNewComponentWithModals(
  AiKnowledgeTopicComponentClass,
  'analytics_ai_knowledge_tier_topic_modal',
  [ExportAiKnowledgeTopicReportStartedModal.key],
  {}
)
