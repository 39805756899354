import * as Types from './types'
export const SHOW_APP_CUE = 'SHOW_APP_CUE'
export const SHOW_APP_CUE_CONFIRM = 'SHOW_APP_CUE_CONFIRM'
export const CLOSE_APP_CUE = 'CLOSE_APP_CUE'
export const MOVE_PAGE = 'MOVE_PAGE'
export const APP_CUES_LOADED = 'APP_CUES_LOAD_SUCCESS'
export const APP_CUE_FREQUENCY = 'APP_CUE_FREQUENCY_'

export enum APP_CUE_TYPE {
  SOURCE_ADDED = 'source_added',
  FILTER_GUIDE = 'filter_guide',
  ARTICLES_100 = 'articles_100',
  COMPANY_INFO_VALIDATION = 'company_info_validation',
}

export const DEFAULT_APP_CUE: Types.AppCue[] = [{
  title: '',
}]
