import {isEmpty} from 'lodash'
import { get } from 'lodash/fp'
import {ajax} from 'rxjs/ajax'
import * as Constants from './constants'

const strictUriEncode = (url: string): string => {
  return encodeURIComponent(url).replace(/[!'()*]/g, (x) => `%${x.charCodeAt(0).toString(16).toUpperCase()}`)
}

export const urlShorten = (shortenUrl: string, share: Constants.SocialMediaShare) => {
  // Skip the URL shortener.  We may revisit this with upcoming work to hit this service more aggressively.
  if (false && shortenUrl) {
    ajax({
      body: {
        metadata: {
          email: share.user.email,
        },
        source: share.type,
        url: share.url,
      },
      crossDomain: false,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      responseType: 'json',
      url: shortenUrl,
    }).toPromise().then((ajaxResponse: any) => {
      openShareWindow(ajaxResponse.response.short_url, share)
    })
  } else {
    openShareWindow(share.url, share)
  }
}

const openShareWindow = (targetUrl: any, share: Constants.SocialMediaShare): void => {
const url = windowUrl(targetUrl, share)
if ( url ) {
  window.open(url, 'mywin', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0')
}
}

const windowUrl = (targetUrl: any, share: Constants.SocialMediaShare): string => {
  switch (share.type) {
    case Constants.SocialMediaTypes.X:
    case Constants.SocialMediaTypes.Twitter:
      return `https://twitter.com/intent/tweet?url=${targetUrl}&text=${strictUriEncode(share.text)}&via=rasa_io`
    case Constants.SocialMediaTypes.LinkedIn:
      return `https://www.linkedin.com/shareArticle?url=${targetUrl}&title=${strictUriEncode(share.text)}&source=rasa.io`
    case Constants.SocialMediaTypes.Facebook:
      return `https://www.facebook.com/sharer.php?u=${targetUrl}&title=${strictUriEncode(share.text)}&source=rasa.io`
    default:
      return null
  }
}

export const twitterShare: Constants.ShareGenerator =
(url: string, text: string, user: any): Constants.SocialMediaShare => {
return {
  text,
  title: 'Twitter',
  type: Constants.SocialMediaTypes.X,
  url,
  user,
}
}

export const linkedInShare: Constants.ShareGenerator =
(url: string, text: string, user: any): Constants.SocialMediaShare => {
return {
  text,
  title: 'LinkedIn',
  type: Constants.SocialMediaTypes.LinkedIn,
  url,
  user,
}
}

export const facebookShare: Constants.ShareGenerator =
(url: string, text: string, user: any): Constants.SocialMediaShare => {
return {
  text,
  title: 'Facebook',
  type: Constants.SocialMediaTypes.Facebook,
  url,
  user,
}
}

export const X: Constants.SocialMediaMaker = {
  generator: twitterShare,
  type: Constants.SocialMediaTypes.X,
}

export const LINKED_IN: Constants.SocialMediaMaker = {
  generator: linkedInShare,
  type: Constants.SocialMediaTypes.LinkedIn,
}
export const FACEBOOK: Constants.SocialMediaMaker = {
  generator: facebookShare,
  type: Constants.SocialMediaTypes.Facebook,
}

export const ALL_SOCIAL_MEDIAS: Constants.SocialMediaMaker[] = [X, LINKED_IN, FACEBOOK]

export const shareUrl = (communityGuid: string, a: any, url: string) => {
  return !isEmpty(communityGuid) && a.issueId && url &&
         `${url}/${communityGuid}/${a.issueId}/${a.postId}`
}

export const getDisplayedClickType = (key: string): any => {
  switch (key) {
    case Constants.ALL_CLICKS_KEY: return {field: 'totalClicks', title: 'Total Clicks'}
    case Constants.REAL_CLICKS_KEY: return {field: 'realClicks', title: 'Real Clicks'}
    default: return {field: 'totalClicks', title: 'Total Clicks'}
  }
}

export const isRealClickSelected = (clickType: string): boolean => {
  return (clickType.toLowerCase() === Constants.REAL_CLICKS_KEY.toLocaleLowerCase())
}

export const isOnlySuspectClickSelected = (clickType: string): boolean => {
  return (clickType.toLowerCase() === Constants.ONLY_SUSPECT_CLICKS_KEY.toLocaleLowerCase())
}

export interface Counter {
  count?: number,
  rate?: number,
}

export interface UniqueCounter {
  total?: Counter,
  unique?: Counter,
}

//TODO:
export const newCounterEntry = (bucket: any, total: any): UniqueCounter => {
  if (bucket) {
    const unique = get('unique', bucket)
    return {
      total: {
        count: bucket ? bucket[Constants.DOC_COUNT] : 0,
      },
      unique: {
        count: unique ? unique.value : 0,
        rate: total && unique ? unique.value / total * 100 : 0,
      },
    }
  }
}

export const AggFieldNames = {
  DeliveryCount: 'delivery_count',
  DocumentCount: 'doc_count',
  Engagement: 'engagement',
  KnowledgeTier: 'knowledge_tier',
  TopArticleClicks: 'top_article_clicks',
  TopSourceClicks: 'top_source_clicks',
  TopTerms: 'top_terms',
  TotalClickCount: 'total_click_count',
  TotalCount: 'total_count',
  TotalOpenCount: 'total_open_count',
  TotalTagCount: 'total_tags',
  TopCommunityPersonIds: 'top_community_person_ids',
  Unique: 'unique',
  UniqueClickCount: 'unique_click_count',
  UniqueCount: 'unique_count',
  UniqueOpenCount: 'unique_open_count',
  UniqueTagCount: 'unique_tags',
}

export const asPercentage = (n: number = 0) => n === 0 ? '0' : (n * 100).toFixed(2)
