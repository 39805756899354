import React from 'react'
import * as Modals from 'shared/modals'

export class CrunchingNumbersModal extends Modals.ModalComponent {
  public static key: string = 'crunchingNumbers'
  constructor(props: Modals.ModalComponentProps) {
    super(props, CrunchingNumbersModal.key)
  }

  protected renderChildren(data: any) {
    return(
      <span>
        We are still crunching the numbers!
        Check back in a few hours to see all of your opens and clicks!
      </span>)
  }
}
