import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { DropdownComponent, MultiSelectDropdown } from 'components/dropdown/component'
import { FeatureUnavailableComponent } from 'components/feature-unavailable'
import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { RasaContext } from 'context'
import * as DateFormat from 'date-fns'
import { DateRangesAsDropdownOptions, esToNormalDateRange, SuspectFilterDropdownOptions } from 'elasticsearch/constants'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import * as GA from 'google-analytics'
import * as React from 'react'
import { Button } from 'reactstrap'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { REPORT_GENERATED } from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'

const events = [{label: 'Clicks', value: 'click'}, {label: 'Opens', value: 'open'}]

const initialState = {
  selectedDateRange: DateRangesAsDropdownOptions[2],
  isReportRunning: true,
  selectedEvents: [],
  hasFeatureAccess: false,
}

class S3ReportsComponentClass extends RasaAnalyticsComponent<any, any> {
  public static contextType = RasaContext
  private communityId: string = null
  constructor(props) {
    super(props, initialState)
  }
  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
    ]).then(([activeCommunity]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.communityId = activeCommunity.communityId
      this.isReportInProgress()
      .then((isReportRunningResult: boolean) => {
        this.setState({
          isReportRunning: isReportRunningResult,
          hasFeatureAccess: activeCommunity.role === Roles.super_admin ||
            avlFeatures.indexOf(BillingPlanDetailCode.WEEKLY_REPORT) > -1,
         })
      })
    })
  }
  public render() {
    return(
      this.state.hasFeatureAccess ?
      <div className="analytics-component">
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Reports'}
        />
        <div className="dropdown-header">
            <div className="dropdown-inline">
              <DropdownComponent data={SuspectFilterDropdownOptions}
                                 selected={this.state.selectedSuspectClick.key}
                                 onChange={this.suspectedClickChanged}/>
            </div>
          <div className="dropdown-inline">
            <DropdownComponent data={DateRangesAsDropdownOptions}
                               selected={this.state.selectedDateRange.key}
                               onChange={this.dateChanged}/>
          </div>
        </div>
        <div className="date-range-picker">
          {this.state.selectedDateRange.key === '7'
          ? <DateRangePicker
              onChange={this.createCustomDate}
              max={new Date()}
              //min={new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) } // 180 days in back
              min={this.state.minCustomDateRange}
            />
          : null}
        </div>
        <div className="dropdown-header">
          <div className="dropdown-inline">
            <MultiSelectDropdown
              placeholder="All Events"
              className={'multi-select-dropdown'}
              defaultValue={null}
              options={events.map((event) => {
                return {
                  label: event.label,
                  value: event.value,
                }
              })}
              onSelect={(e) => this.onEventsFilterChange(e)}
            />
          </div>
        </div>
        <div className="run-report-btn">
          <Button className="btn" value="Add Article"
            disabled={this.state.isReportRunning}
            onClick={() => this.runReport()}>Run Report
          </Button>
          {this.state.isReportRunning &&
          <div className="message">A report is already in process. Please try after current report is complete.</div>}</div>
        <div>
          {this.state.isReportRunning &&
          <div className="last-report-in-progress"></div>}
        </div>
      </div> :
      <FeatureUnavailableComponent source={GA.UpgradeSource.WeeklyReport}/>
    )
  }

  private isReportInProgress = (): Promise<boolean> => {
    return new Dataset().loadCommunityDataset('newsletterRuns', this.communityId,
      [{param: 'runType', value: 'weekly_report'}, {param: 'status', value: 'Pending'}]).then((pendingRuns) => {
        if (pendingRuns[0].length && new Date() < DateFormat.addHours(new Date(pendingRuns[0][0].created), 6)) {
          return true
        } else {
          return false
        }
      })
  }

  private onEventsFilterChange(selections: any[]) {
    this.setState({
      selectedEvents: selections,
    })
  }

  private runReport = () => {
    this.setState({
      isReportRunning: true,
    })
    const dateRanges = esToNormalDateRange(this.state.selectedDateRange.value).split('|')
    const searchPayload = {
      start_date: new Date(dateRanges[0]).toISOString().slice(0, 10),
      end_date: new Date(dateRanges[1]).toISOString().slice(0, 10),
      events: this.state.selectedEvents.length
        ? this.state.selectedEvents.map((s) => s.value).join(',')
        : events.map((s) => s.value).join(','),
    }
    this.context.entityMetadata
      .getEntityObject('newsletterRun')
      .then((entity) => {
        entity.setRecordId(this.communityId, null)
        entity.data.id = null
        entity.data.run_type = 'weekly_report'
        entity.data.status = 'Pending'
        entity.data.run_attributes = JSON.stringify(searchPayload)
        return entity.save().then(() => {
          this.context.store.dispatch(Flash.showFlashMessage(REPORT_GENERATED))
        })
      })
  }
}

export const S3ReportsComponent = GenericRedux.registerNewComponent(
  S3ReportsComponentClass, 's3_reports', {})
