import * as KendoChart from '@progress/kendo-react-charts'
import React from 'react'
import './_styles.scss'

export interface PieChartData {
  color: string,
  category: string,
  totalRecords: number,
}

export interface PieChartProps {
  data: PieChartData[]
  field: string
  gridLabel: string
  totalCount: number,
  enableTooltip: boolean
  isValueInPercent?: boolean
  showDataGrid?: boolean
}

export const PieChart = (props: PieChartProps) => {
  const chartRef = React.useRef(null)

  const toggleSeriesHighlight = (isHighlighted: boolean, category: string) => {
    const chartInstance = chartRef.current.chartInstance
    chartInstance.toggleHighlight(isHighlighted, (i) => {
      return i.dataItem.category === category
    })
  }

  const convertValueToPercent = (value) => {
    return props.isValueInPercent ? value : Number(value / props.totalCount)
      .toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 2,
      }).replace('%', '')
  }

  return (
    <div>
      <div className={'gr-graph-wrapper'}>
        <KendoChart.Chart
          transitions={false}
          pannable={false}
          zoomable={false}
          ref={chartRef}
        >
          <KendoChart.ChartLegend position="bottom" orientation="horizontal" visible={!props.showDataGrid}/>
          <KendoChart.ChartSeries>
            <KendoChart.ChartSeriesItem
              type="donut"
              tooltip={{
                visible: props.enableTooltip,
              }}
              data={props.data}
              field="totalRecords"
              categoryField="category"
            >
              <KendoChart.ChartSeriesLabels
                background="none"
                content={(e) => convertValueToPercent(e.value)}
              />
            </KendoChart.ChartSeriesItem>
          </KendoChart.ChartSeries>
        </KendoChart.Chart>
        { props.showDataGrid &&
          <div className="table-wrapper--graph">
            <table>
              <thead>
              <tr>
                <th>{props.gridLabel.toUpperCase()}</th>
                <th>PERCENTAGE</th>
                <th>COUNTS</th>
              </tr>
              </thead>
              <tbody>
              {props.data.map((item, index) => {
                return <tr
                  key={index}
                  onMouseEnter={() => toggleSeriesHighlight(true, item.category)}
                  onMouseLeave={() => toggleSeriesHighlight(false, item.category)}
                >
                  <td>
                    <span className={'gr-dot'} style={{backgroundColor: item.color}}></span>&nbsp;
                    <strong className={'gr-title'}>{item.category}</strong>
                  </td>
                  <td>{convertValueToPercent(item.totalRecords)}</td>
                  <td>{item.totalRecords}</td>
                </tr>
              })}
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  )
}
