import * as KendoChart from '@progress/kendo-react-charts'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { DropdownComponent} from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { format, isAfter, isSameDay, parseISO } from 'date-fns'
import { AggregationType, FilterType, IndexName, SuspectFilterDropdownOptions } from 'elasticsearch/constants'
import { ResponsePayload } from 'elasticsearch/types'
import { Dataset, DatasetParam } from 'generic/dataset'
import {
  ElasticsearchComponent,
  ElasticsearchProps,
} from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import { uniqWith } from 'lodash'
import * as React from 'react'
import { Col, Row } from 'reactstrap'
import * as Constants from './constants'
import { CrunchingNumbersModal } from './modals'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import { ComponentType } from 'react'
import {Fields} from "../../shared/modals";
import * as Utils from './utils'

interface TotalCountComponentProps {
  count: number,
  nonCount: number,
  type1: string,
  type2: string,
}

const TotalCountComponent = (props: TotalCountComponentProps) => <div>
  <div className="big-numbers chart">
    <span className="title"> Total {props.type1}</span>
    <p className="primary">{(props.count || 0)}</p>
    <p className="secondary">{(props.nonCount || 0)} {props.type2}</p>
  </div>
</div>

interface CountsComponentProps {
  name: string,
  sent: number,
  total: number,
  unique: number,
}
const CountsComponent = (props: CountsComponentProps) => <div>
  <div className="big-numbers chart">
    <span className="title"> Unique {props.name}:</span>
    <p className="primary">
      {((props.sent || 0) > 0 ? ((props.unique || 0) / props.sent * 100) : 0).toFixed(1)}&nbsp;%
    </p>
    <p className="secondary">
      Total {props.name}: {((props.sent || 0) > 0 ? ((props.total || 0) / props.sent * 100) : 0).toFixed(1)}&nbsp;%
    </p>
  </div>
</div>

const Colors = {
  click: '#8fd1bb',
  open: '#fcc3a7',
  sent: '#b2d4f5',
}
interface KendoChartSeries {
  color: string,
  data: number[],
  key: any,
  label: string,
}
export interface HourlyActionCounts {
  hour: number,
  totalClicks?: number,
  totalOpens?: number,
  uniqueClicks?: number,
  uniqueOpens?: number,
}
interface HourlyProps {
  hourly: HourlyActionCounts[],
}
const hourlyLabel = (hour: number, index: number, numHours: number): string => {
  if (numHours <= 12 || (index % 2 === 0) ) {
    switch (hour) {
      case 0:
      case 24:
        return '12a'
      case 12:
        return '12p'
      default:
        return hour < 12 ? `${hour}a` : `${hour % 12}p`
    }
  } else {
    return ''
  }
}

const hourLabels = (hourly: HourlyActionCounts[]): string[] => {
  return hourly.map((h: HourlyActionCounts, index: number) =>
    hourlyLabel(h.hour, index, hourly.length))
}

const asChartSeries = (hourly: HourlyActionCounts[]): KendoChartSeries[] => {
  const fullEntries: HourlyActionCounts[] = hourly
  return [
    {
      color: Colors.open,
      data: fullEntries.map((f) => f.uniqueOpens),
      key: 'open',
      label: 'Opens',
    },
    {
      color: Colors.click,
      data: fullEntries.map((f) => f.uniqueClicks),
      key: 'click',
      label: 'Clicks',
    },
  ]
}
const hourlyTooltip = (context: KendoChart.TooltipContext) => <div>{context.point.value}</div>
const HourlyComponent = ({hourly}: HourlyProps) =>
  <div className="hourly-chart">
    <KendoChart.Chart zoomable={false} >
      <KendoChart.ChartTitle text="By Hour"/>
      <KendoChart.ChartTooltip shared={false} render={hourlyTooltip}/>
      <KendoChart.ChartCategoryAxis>
        <KendoChart.ChartCategoryAxisItem categories={hourLabels(hourly)} startAngle={45} />
      </KendoChart.ChartCategoryAxis>
      <KendoChart.ChartSeries>
        {asChartSeries(hourly).map((item, idx) =>
        <KendoChart.ChartSeriesItem key={idx} type="column" data={item.data} name={item.label} color={item.color}/>)}
      </KendoChart.ChartSeries>
    </KendoChart.Chart>
  </div>

interface SubjectActionCounts {
  openRate: number,
  text: number,
  totalSends?: number,
  totalOpens?: number,
  uniqueOpens?: number,
  uniqueOpenRate?: number,
}

interface SubjectProps {
  subjects: SubjectActionCounts[],
}

const SubjectComponent = ({subjects}: SubjectProps) =>
  <Grid data={subjects}>
    <GridColumn field="text" title="Subject"/>
    <GridColumn field="totalSends" title="Sends" width={100}/>
    <GridColumn field="uniqueOpens" title="Opens" width={100}/>
    <GridColumn field="uniqueOpenRate" title="%" width={100}/>
  </Grid>

const dailyStatsInitialState = {
  issueLoading: true,
  issues: [],
  selectedIssue: {key: 'No Issue', value: -1},
  deliveryCount: null,
  communityId: null,
}

interface DailyStatsAdditionalProps {
  hideHeader?: boolean
}

type DailyStatsComponentProps = GenericRedux.AllComponentPropsWithModal<any>
                                & DailyStatsAdditionalProps

interface DailyStatsState {
  alreadySentToday?: boolean
}

export class DailyStatsComponentClass extends RasaAnalyticsComponent<DailyStatsComponentProps, DailyStatsState> {
  public static contextType = RasaContext

  constructor(props) {
    super(props, dailyStatsInitialState)
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
      const searchParams = new URLSearchParams(document.location.search)
      const dateFilter = searchParams.get('date_filter')
      const lastSent = new Date(parseISO(activeCommunity.data.last_sent))
      const sentToday = isSameDay(lastSent, new Date())
      const alreadySent = isAfter(new Date(), lastSent)
      this.setState({
        alreadySentToday: sentToday && alreadySent,
        communityId: activeCommunity.communityId,
      })
      return this.loadIssues(activeCommunity.communityId, dateFilter)
    })
  }

  public getDeliveryCount: DeliveryCountFunction = (deliveries: number) => {
    if (deliveries === 0 && this.state.alreadySentToday) {
      this.props.openModal(CrunchingNumbersModal.key, {})
    }
  }

  public render() {
    return (
      <div className="analytics-component daily-stats">
        <div className="modal-wrapper">
          <CrunchingNumbersModal data={this.props.modals}
                                 closeModal={this.props.closeModal}
                                 title=""/>
        </div>
        {!this.props.hideHeader &&
        <HeaderComponent
          title={'ANALYTICS'}
          subTitle={'Daily Stats'}
        />
        }
        {this.state.issueLoading ?
          <Loading size="64" /> :
          (this.state.issues.length && !this.props.hideHeader) ?
          <div>
            <div className="daily-stats-container">
              <Row>
                <Col md="4">
                  <Row className="issue-date-dropdown">
                    <Col>
                      <div className="dropdown">
                        <DropdownComponent data={this.state.issues}
                                           selected={this.state.selectedIssue.key}
                                           onChange={this.issueChanged}/>
                      </div>
                      <div className="dropdown">
                        <DropdownComponent data={SuspectFilterDropdownOptions}
                                           selected={this.state.selectedSuspectClick.key}
                                           onChange={this.suspectedClickChanged}/>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <AnalyticsDailyStatsComponent getDeliveryCount={this.getDeliveryCount}
                                          communityId={this.state.communityId}
                                          issue={this.state.selectedIssue.value}
                                          suspectClick={this.state.selectedSuspectClick.value}/>
          </div>
          :
          <div>
            <p className="no-data-tag">
              {Constants.NO_DATA_COPY}
            </p>
        </div>
        }
      </div>
    )
  }

  private loadIssues = (communityId, dateFilter) => {
    const params: DatasetParam[] = [
      {param: 'pageSize', value: 10},
    ]
    if (dateFilter && (new Date(parseInt(dateFilter, 10))).getTime() > 0) {
      const date = new Date(parseInt(dateFilter, 10)).toISOString().split('T')[0]
      params.push(
        {param: 'startDate', value: date},
        {param: 'endDate', value: date},
      )
    }
    return new Dataset().loadCommunityDataset('communityIssues', communityId, params)
      .then((issues) => {
        let issuesData = issues[0].map((i) => {
          const d: Date = new Date(i.send_at_in_timezone)
          const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
          const key = format(new Date(dateWithoutOffset), 'iiii, MMM d H:mm')
          return {
            key,
            value: i.id,
          }
        })
        issuesData = uniqWith(issuesData, (d: any, i: any) => d.key === i.key)
        this.setState({
          issueLoading: false,
          issues: issuesData,
        })
        if (issuesData.length) {
          this.setState({selectedIssue: issuesData[0]})
        }
      })
  }

  private issueChanged = (e: any) => {
    this.setState({selectedIssue: e.selected})
  }
}

interface Stats {
  hourly: any,
  nonDelivered: number,
  subjects: any,
  totalClicks?: number,
  totalDelivers?: number,
  totalOpens?: number,
  uniqueClicks?: number,
  uniqueOpens?: number,
  suspectClick?: number
}

type DeliveryCountFunction = (count: number) => void
interface DailyStatsProps extends ElasticsearchProps<Stats> {
  issue: string,
  getDeliveryCount: DeliveryCountFunction,
  communityId: string,
  suspectClick?: any,
}

interface EngagementState {
  loaded: boolean,
  subscribedCount: number,
  unsubscribedCount: number,
}

const EVENT_NAME_AGGREGATION: string = 'event_name'
const HOURLY_COUNT_AGGREGATION: string = 'hourly_count'
const SUBJECT_LINE_AGGREGATION: string = 'subject_line'

class DailyStatsClass extends ElasticsearchComponent<Stats, DailyStatsProps, EngagementState> {

  constructor(p: DailyStatsProps) {
    super(p, IndexName.EVENTS)
    this.state = {
      loaded: false,
      subscribedCount: 0,
      unsubscribedCount: 0,
    }
    this.reportName = Constants.REPORT_NAMES.DAILY_STATS
  }

  public parseResponse(payload: ResponsePayload): Promise<Stats> {
    const eventNameAggregations = payload.aggregations[EVENT_NAME_AGGREGATION]
    const hourlyAggregations = payload.aggregations[HOURLY_COUNT_AGGREGATION]
    const subjectsAggregations = payload.aggregations[SUBJECT_LINE_AGGREGATION]

    const delivers = this.getAggregation(eventNameAggregations, 'delivered')
    const opens = this.getAggregation(eventNameAggregations, 'open')
    const clicks = this.getAggregation(eventNameAggregations, 'click')
    const softBounce = this.getAggregation(eventNameAggregations, 'soft_bounce');
    const hardBounce = this.getAggregation(eventNameAggregations, 'hard_bounce');
    const spamReport = this.getAggregation(eventNameAggregations, 'spamreport')
    const dropped = this.getAggregation(eventNameAggregations, 'dropped')

    const nonDelivered = (softBounce && softBounce.unique ? softBounce.unique.value : 0) +
      (hardBounce && hardBounce.unique ? hardBounce.unique.value : 0) +
      (spamReport && spamReport.unique ? spamReport.unique.value : 0) +
      (dropped && dropped.unique ? dropped.unique.value : 0)

    const hourlyData = hourlyAggregations.buckets.slice(0, 24).map((hourly) => {
      const hourlyOpens = this.getAggregation(hourly.child, 'open')
      const hourlyClicks = this.getAggregation(hourly.child, 'click')
      return {
        hour: new Date(hourly.key).getHours(),
        totalClicks: hourlyClicks ? hourlyClicks.doc_count : 0,
        totalOpens: hourlyOpens ? hourlyOpens.doc_count : 0,
        uniqueClicks: hourlyClicks && hourlyClicks.unique ? hourlyClicks.unique.value : 0,
        uniqueOpens: hourlyOpens && hourlyOpens.unique ? hourlyOpens.unique.value : 0,
      }
    })

    const subjectsData = subjectsAggregations.buckets.map((subject) => {
      const subjectSends = this.getAggregation(subject.child, 'delivered')
      const subjectOpens = this.getAggregation(subject.child, 'open')
      return {
        text: subject.key,
        totalOpens: subjectOpens ? subjectOpens.doc_count : 0,
        uniqueOpens: subjectOpens && subjectOpens.unique ? subjectOpens.unique.value : 0,
        totalSends: subjectSends ? subjectSends.doc_count : 0,
        openRate:
        (((subjectOpens ? subjectOpens.doc_count : 0) / (subjectSends ? subjectSends.doc_count : 1)) * 100).toFixed(2),
        uniqueOpenRate:
        (((subjectOpens && subjectOpens.unique ? subjectOpens.unique.value : 0) /
        (subjectSends && subjectSends.unique ? subjectSends.unique.value : 1)) * 100).toFixed(2),
      }
    })

    this.setState({
      loaded: true,
    })
    this.props.getDeliveryCount(delivers ? delivers.doc_count : 0)
    return Promise.resolve({
      hourly: hourlyData ? hourlyData : [],
      nonDelivered,
      subjects: subjectsData ? subjectsData : [],
      totalClicks: clicks ? clicks.doc_count : 0,
      totalDelivers: delivers ? delivers.doc_count : 0,
      totalOpens: opens ? opens.doc_count : 0,
      uniqueClicks: clicks && clicks.unique ? clicks.unique.value : 0,
      uniqueOpens: opens && opens.unique ? opens.unique.value : 0,
    })
  }

  public componentDidUpdate(oldProps: DailyStatsProps) {
    if ( this.props.issue !== oldProps.issue || this.props.suspectClick !== oldProps.suspectClick ) {
      this.setState({loaded: false})
      if ( this.communityId ) {
        this.search()
        this.loadSubscriberCount()
      }
    }
  }

  public searchPayload(): any {
    const search = bodybuilder().size(0)
    .sort('created', 'desc')
    .filter(FilterType.terms, 'issue_id', [this.props.issue])

    if (this.props.suspectClick && Utils.isRealClickSelected(this.props.suspectClick)) {
      search.addFilter(FilterType.term, 'suspect_click', 0)
    }

    this.addAggregation(search, {
      type: AggregationType.terms,
      field: 'event_name.keyword',
      name: EVENT_NAME_AGGREGATION,
      unique_on: 'community_person_id',
    })
    this.addAggregation(search, {
      type: AggregationType.date_histogram,
      field: 'event_timestamp',
      extra: { interval: 'hour' },
      name: HOURLY_COUNT_AGGREGATION,
      child: {
        type: AggregationType.terms,
        field: 'event_name.keyword',
        name: EVENT_NAME_AGGREGATION,
        unique_on: 'community_person_id',
      },
    })
    return this.addAggregation(search, {
      type: AggregationType.terms,
      field: 'email_subject.keyword',
      name: SUBJECT_LINE_AGGREGATION,
      child: {
        type: AggregationType.terms,
        field: 'event_name.keyword',
        name: EVENT_NAME_AGGREGATION,
        unique_on: 'community_person_id',
      },
    }).build()
  }

  public render = () => {
    return <div>
      {this.props.results && this.state.loaded
      ? <div className="daily-stats-container">
        <Row>
          <Col md="4">
            <Row>
              <Col className="counts-component-container deliveriesbox">
                <TotalCountComponent type1="Deliverables" type2="Non Deliverables"
                  count={this.props.results.totalDelivers}
                  nonCount={this.props.results.nonDelivered} />
              </Col>
            </Row>
            <Row>
              <Col className="counts-component-container opensbox">
                <CountsComponent name="Opens"
                  sent={this.props.results.totalDelivers}
                  total={this.props.results.totalOpens}
                  unique ={this.props.results.uniqueOpens} />
              </Col>
            </Row>
            <Row>
              <Col className="counts-component-container clicksbox">
                <CountsComponent name="Clicks"
                  sent={this.props.results.totalDelivers}
                  total={this.props.results.totalClicks}
                  unique ={this.props.results.uniqueClicks} />
              </Col>
            </Row>
            <Row>
              <Col className="counts-component-container deliveriesbox">
                <TotalCountComponent type1="Subscribed" type2="UnSubscribed"
                  count={this.state.subscribedCount}
                  nonCount={this.state.unsubscribedCount} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="hourly-stats">
              <Col>
                <HourlyComponent hourly={this.props.results.hourly} />
              </Col>
            </Row>
            {this.props.results.subjects && this.props.results.subjects.length > 1 &&
            <Row className="subject-lines">
              <Col>
                <SubjectComponent subjects={this.props.results.subjects}/>
              </Col>
            </Row>}
          </Col>
        </Row>
      </div>
      : <Loading size="64" />
      }
  </div>
  }
  private loadSubscriberCount = () => {
    return new Dataset().loadCommunityDataset('communitySubscribersCountByIssue', this.props.communityId,
          [{param: 'issueId', value: this.props.issue}])
      .then((counts) => {
        if (counts[0] && counts[0][0]) {
          this.setState({
            subscribedCount: counts[0][0].subscribed,
            unsubscribedCount: counts[0][0].unsubscribed,
          })
        }
      })
  }
}

export const AnalyticsDailyStatsComponent: ConnectedComponentClass<ComponentType<DailyStatsClass>, Fields> = GenericRedux.registerNewComponent(
  DailyStatsClass, 'analytics_dailystats', {},
)

export const DailyStatsComponent = GenericRedux.registerNewComponentWithModals<any>(
  DailyStatsComponentClass,
  'analytics_dailystats_modal',
  [CrunchingNumbersModal.key],
  dailyStatsInitialState,
)
