import * as Constants from './constants'

export const closeBanner = (notificationName: string) => ({
  type: Constants.CLOSE_BANNER,
  notificationName,
})

export const openBanner = (notificationName: string) => ({
  type: Constants.OPEN_BANNER,
  notificationName,
})
