import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import bodybuilder from 'bodybuilder'
import { DropdownComponent } from 'components/dropdown/component'
import { HeaderComponent } from 'components/header/component'
import { DateRangesAsDropdownOptions, FilterType, IndexName, toFilter } from 'elasticsearch/constants'
import { ResponseAggregate, ResponsePayload } from 'elasticsearch/types'
import { ElasticsearchComponent, ElasticsearchProps } from 'generic/elasticSearchComponent'
import * as GenericRedux from 'generic/genericRedux'
import React, { ComponentType } from 'react'
import { Button } from 'reactstrap'
import * as Constants from './constants'
import { ContentFilterOption, ContentFilters } from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'
import { ConnectedComponentClass } from 'react-redux'
import { Fields } from '../../shared/modals'

export class UrlRequestContentBlockComponentClass extends RasaAnalyticsComponent<any, any> {
  public urlRequestContentBlockTableRef
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      selectedDateRange: DateRangesAsDropdownOptions.filter((x) => x.value ===
        this.getQuery(Constants.AnalyticsFilterKeys.SELECTED_DATE_RANGE, DateRangesAsDropdownOptions[4].value))[0],
      selectedContentBlock: ContentFilters.filter((x) => x.value ===
      this.getQuery(Constants.AnalyticsFilterKeys.SELECTED_CONTENT_BLOCK, ContentFilters[0].value))[0],
    }
    this.urlRequestContentBlockTableRef = React.createRef()
    this.invokeSearch = this.invokeSearch.bind(this)
  }

  public componentDidMount() {
    super.componentDidMount()
    this.minCustomDateRange()
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDateRange !== this.state.selectedDateRange ||
      prevState.selectedContentBlock !== this.state.selectedContentBlock) {
      this.invokeSearch()
    }
  }

  public render() {
    return (
      <div className="analytics-component">
        <HeaderComponent
          title={'Url Request Content Block'}
          subTitle={'Request Content Block'}
          description={[
            'Get Content Block for your url.',
          ]}
        />
        <div className="dropdown">
          <DropdownComponent data={DateRangesAsDropdownOptions}
                             selected={this.state.selectedDateRange.key}
                             onChange={this.dateChanged}/>
        </div>
        <div className="date-range-picker">
          {this.state.selectedDateRange.key === '7' ?
            <DateRangePicker onChange={this.createCustomDate}
                             min={this.state.minCustomDateRange} max={new Date()}/> : null}
        </div>
        <div className="dropdown">
          <DropdownComponent data={Constants.ContentFilters} selected={this.state.selectedContentBlock.key}
                             onChange={this.contentBlockChanged}/>
        </div>

        <UrlRequestContentBlockTable
          ref={(urlRequestContentBlock) => {
            this.urlRequestContentBlockTableRef = urlRequestContentBlock
          }}
          dateRange={this.state.selectedDateRange.value}
          selectedContentBlock={this.state.selectedContentBlock}/>
      </div>
    )
  }

  private invokeSearch () {
    this.urlRequestContentBlockTableRef.search()
  }
}

interface UrlRequest {
  domain: string,
  request_type: string,
  request_url: string,
  response_time: number,
  response_url : string,
  status_code: number,
  created: string,
}

type UrlRequests = UrlRequest[]

interface ContentBlock {
  key: string,
  value: string
}
interface UrlRequestContentBlockProps extends ElasticsearchProps<UrlRequests> {
  dateRange: string,
  selectedContentBlock: ContentBlock,
}

interface UrlRequestContentBlockState {
  loaded: boolean,
  buckets: ResponseAggregate[],
  showDetail: boolean,
  clickedCellData: string,
}

interface UrlRequestContentDetailState {
  loaded: boolean,
}

interface UrlRequestContentBlockDetail extends ElasticsearchProps<UrlRequests> {
  dateRange: string,
  cellData: string,
  requestKey: string
}

class UrlRequestContentBlockTableComponent extends ElasticsearchComponent<UrlRequests, UrlRequestContentBlockProps, UrlRequestContentBlockState> {
  public urlRequestContentBlockDetailRef
  private xlsxExport: any = null
  constructor(p: UrlRequestContentBlockProps) {
    super(p, IndexName.URL_REQUESTS)
    this.state = {
      loaded: false,
      buckets: [],
      showDetail: false,
      clickedCellData: '',
    }
    this.reportName = Constants.REPORT_NAMES.URL_REQUESTS
    this.urlRequestContentBlockDetailRef = React.createRef()
    this.invokeSearch = this.invokeSearch.bind(this)
    this.getCell = this.getCell.bind(this)
  }

  public parseResponse(payload: ResponsePayload): Promise<UrlRequests> {
    this.setState({
      loaded: true,
      buckets: payload.aggregations.agg_terms_result.buckets
    })
    return Promise.resolve(payload.hits.hits.map((hit) => hit._source as UrlRequest))
  }

  public searchPayload(): any {
    this.setState({
      showDetail: false
    })
    return this.generateSearchQuery().build()
  }

  public render = () => <div>
    <div className="topics-chart">
      {this.state.buckets && this.state.buckets.length > 0 ?
        <div>
          {this.state.showDetail ?
            (<UrlRequestContentBlockDetail
                  ref={(urlRequestContentBlockDetail) => {
                    this.urlRequestContentBlockDetailRef = urlRequestContentBlockDetail
                  }}
                  dateRange={this.props.dateRange}
                  cellData={this.state.clickedCellData}
                  requestKey={this.props.selectedContentBlock.value}
            />) :
            (<><Button
            disabled={this.state.buckets.length < 1}
            onClick={() => this.xlsxExport.save()}>
            Export xlsx
          </Button>
          <ExcelExport data={this.state.buckets}
                       fileName="RasaAdminReports.xlsx"
                       ref={(exporter) => {this.xlsxExport = exporter}}>
            <Grid data={this.state.buckets} className="analytics-counts-grid" scrollable="none" onRowClick={this.getCell}>
              <GridColumn field="key" title="Url"/>
              <GridColumn field="doc_count" title="Count"/>
            </Grid>
          </ExcelExport></>)}
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
        </div>
      }
    </div>
  </div>

  private getCell(event: any): any {
    if (event.dataItem) {
      this.setState({
        showDetail: true,
        clickedCellData: event.dataItem.key
      })
    } else {
      this.setState({
        showDetail: false,
        clickedCellData: ''
      })
    }
  }

  private generateSearchQuery = (): bodybuilder.Bodybuilder => {
    let key = `${this.props.selectedContentBlock.value}.keyword`
    if (this.props.selectedContentBlock.value === ContentFilterOption.STATUS_CODE) {
      key = `${this.props.selectedContentBlock.value}`}
    return bodybuilder().size(100)
      .filter(FilterType.range, 'created', toFilter(this.props.dateRange))
      .aggregation('terms', 'result', {field: key})
  }

  private invokeSearch = () => {
    this.urlRequestContentBlockDetailRef.search()
  }
}

class UrlRequestContentBlockDetailComponent extends ElasticsearchComponent<UrlRequests, UrlRequestContentBlockDetail, UrlRequestContentDetailState> {
  private xlsxExport: any = null
  constructor(p: UrlRequestContentBlockDetail) {
    super(p, IndexName.URL_REQUESTS)
    this.state = {
      loaded: false,
    }
    this.reportName = Constants.REPORT_NAMES.URL_REQUESTS
  }

  public parseResponse(payload: ResponsePayload): Promise<UrlRequests> {
    this.setState({
      loaded: true,
    })
    return Promise.resolve(payload.hits.hits.map((hit) => hit._source as UrlRequest))
  }

  public componentDidUpdate(prevProps: UrlRequestContentBlockDetail) {
    if (prevProps.cellData !== this.props.cellData || prevProps.dateRange !== this.props.dateRange) {
      this.search();
    }
  }

  public searchPayload(): any {
   return this.generateSearchQuery().build()
  }

  public render = () => <div>
    <div className="topics-chart">
      {this.props.results && this.props.results.length > 0 ?
        <div>
          <Button
            disabled={this.props.results.length < 1}
            onClick={() => this.xlsxExport.save()}>
            Export xlsx
          </Button>
          <ExcelExport data={this.props.results}
                       fileName="RasaAdminReports.xlsx"
                       ref={(exporter) => {this.xlsxExport = exporter}}>
            <Grid data={this.props.results} className="analytics-counts-grid" scrollable="none">
              <GridColumn field="domain" title="Domain"/>
              <GridColumn field="request_source" title="Request source"/>
              <GridColumn field="request_type" title="Request type"/>
              <GridColumn field="status_code" title="Status code"/>
              <GridColumn field="request_url" title="Request Url"/>
            </Grid>
          </ExcelExport>
        </div> :
        <div>
          <p className="no-data-tag">
            {Constants.NO_DATA_COPY}
          </p>
        </div>
      }
    </div>
  </div>
  private generateSearchQuery = (): bodybuilder.Bodybuilder => {
    let key = `${this.props.requestKey}.keyword`
    if (this.props.requestKey=== ContentFilterOption.STATUS_CODE) {
      key = `${this.props.requestKey}`}
    return bodybuilder().size(100).filter(
      FilterType.range, 'created', toFilter(this.props.dateRange)
    ).filter(FilterType.term, key, this.props.cellData)
  }
}

export const UrlRequestContentBlockDetail: ConnectedComponentClass<ComponentType<UrlRequestContentBlockDetailComponent>, Fields> = GenericRedux.registerNewComponentWithRef(
  UrlRequestContentBlockDetailComponent, 'url_request_content_block_detail', {})


export const UrlRequestContentBlockTable: ConnectedComponentClass<ComponentType<UrlRequestContentBlockTableComponent>, Fields> = GenericRedux.registerNewComponentWithRef(
  UrlRequestContentBlockTableComponent, 'url_request_content_block_table', {})

export const UrlRequestContentBlockComponent = GenericRedux.registerNewComponent(
  UrlRequestContentBlockComponentClass,
  'url_request_content_block',
  {}
)
