import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumn} from '@progress/kendo-react-grid'
import { FailureMsgCell } from 'components/content-pool/kendocells/failureMsgCell'
import { DateTimeCell } from 'components/gridcells/datetime'
import { HeaderComponent } from 'components/header/component'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import * as React from 'react'
import { Button } from 'reactstrap'
import * as Constants from './constants'
import { RasaAnalyticsComponent } from './rasa-analytics-component'
import './styles.css'

export class SourcesRunReportComponent extends RasaAnalyticsComponent<any, any> {
  public static contextType = RasaContext;
  constructor(props) {
    super(props)
  }

  public componentDidMount() {
    this.context.user.init().then(({person, activeCommunity}) => {
    this.setState({
      issuesLoading: false,
      communityId: activeCommunity.communityId,
      selectedIssue: {key: 'Select a Newsletter', value: -1},
      })
    })
  }

  public render() {
    return(
      <div className="analytics-component">
        <HeaderComponent
          title={'REPORTS'}
          subTitle={'Newsletter Source Report'}
          description={['This report shows how many active articles from a past send were from each source.']}
        />
        {this.state.issuesLoading ?
          <Loading size="64" /> :
          (!this.props.hideHeader) ?
          <div>
            <SourceReportTableComponent />
          </div>
          :
          <div>
            <p className="no-data-tag">{Constants.NO_DATA_COPY}</p>
          </div>
        }
    </div>
    )
  }
}

interface SourceReportsState {
  loaded?: boolean,
  sources?: any[],
  search?: string,
}

interface SourcesReportsProps {
  sourceName?: any,
  issue_id?: string,
}

class SourceReportTableComponent extends React.Component<SourcesReportsProps,
  SourceReportsState> {
  public static contextType = RasaContext
  private xlsxExport: any = null
  constructor(props: any) {
    super(props)
    this.state = {
      loaded: false,
      sources: [],
      search: '',
    }
  }

  public render = () => {
   return <div>
    <div className="articles-chart">
      <div className="articles">
      <div className="search-bar">
              <label>
                <SearchIcon/>
                <input type="text"
                  placeholder="Search by title, description, source_name or url"
                  onChange={(e) => this.setSearch(e.target.value)}/>
              </label>
            </div>
        <Button
          onClick={() => this.xlsxExport.save()}>
          Export xlsx
        </Button>
        <ExcelExport data={this.state.sources}
          fileName="RasaSourceReports.xlsx"
          ref={(exporter) => {this.xlsxExport = exporter}}>
        <RasaDataGrid
            // Rasa Properties for entity, datasetName etc
            entityName="sourcesInfo"
            datasetName="sourcesInfo"
            datasetParams={[
              { param: 'filterVal', value: this.state.search },
            ]}
            editDefaultState={false}
            // events
            onDataReceived={this.dataReceived}
            sortable={true}
            pageable={true}
            pageSize={1000}
            data={[]}>
            <GridColumn field="identifier" title="Identifier" width={250}/>
            <GridColumn field="type" title="Type" width={100}/>
            <GridColumn field="name" title="Name" width={180}/>
            <GridColumn field="total_subscription" title="Subscriptions" width={120}/>
            <GridColumn field="last_post_date" title="Last Post Date" cell={DateTimeCell} width={120}/>
            <GridColumn field="last_success" title="Last Success" cell={DateTimeCell} width={120}/>
            <GridColumn field="last_failure" title="Last Failure" cell={DateTimeCell} width={120}/>
            <GridColumn field="last_failure_reason" title="Failure Reason" cell={FailureMsgCell}  width={120}/>
            <GridColumn field="updated" title="Updated" cell={DateTimeCell} width={120}/>
          </RasaDataGrid>
        </ExcelExport>
      </div>
    </div>
  </div>
  }

  protected dataReceived = (data) => {
		if (data.data) {
			this.setState({
				sources: data.data,
			})
		}
	}

  protected filterData = (s: any): boolean => {
    const lowerSearch = this.state.search.toLocaleLowerCase()
    return this.state.search.length <= 3 || (
      (s.name && s.name.toLowerCase().indexOf(lowerSearch) >= 0)
      || (s.id && s.id.toString().toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
      || (s.type && s.type.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
      || (s.identifier && s.identifier.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
      || (s.last_failuree_reason && s.last_failuree_reason.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
      || (s.source_url && s.source_url.toLowerCase().indexOf(this.state.search.toLowerCase()) >= 0)
    )
  }

  private setSearch = (s: string) => {
    this.setState({
      search: s,
    })
  }
}
export const AnalyticsSourcesfailureReportTable = GenericRedux.registerNewDatasetContainerComponent(
  SourceReportTableComponent, 'sourcereports')
